import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import icons from '../../assets/icons';
import preferencesTemplate from '../project/preferences';

const insightsTableHeaders = {
  audience: () => [
    {
      Header: 'Device Name',
      id: 'deviceName',
      type: 'string',
      hideTitle: true,
      accessor: d => (d.deviceName ? renderTrimmedValue(d, 'deviceName', d.deviceId) : '--')
    },
    {
      Header: 'Impressions',
      id: 'impressions',
      type: 'number',
      accessor: d => d.impressions
    },
    {
      Header: 'Views',
      id: 'views',
      type: 'number',
      accessor: d => d.views
    },
    {
      Header: 'Age',
      id: 'ageGroup',
      type: 'number',
      accessor: data => renderAge(data),
      disableSortBy: true
    },
    {
      Header: 'Gender',
      id: 'gender',
      type: 'number',
      accessor: data => renderGender(data)
    },
    {
      Header: (
        <div>
          <span>Hourly</span>
          <span className="header-subtitle">12am to 11pm</span>
        </div>
      ),
      id: 'dailyImpressions',
      type: 'number',
      accessor: data => renderDailyImpressions(data, data.deviceId),
      disableSortBy: true
    },
    {
      Header: (
        <div>
          <span>Daily</span>
          <span className="header-subtitle">Sunday to Saturday</span>
        </div>
      ),
      id: 'weeklyImpressions',
      type: 'number',
      accessor: data => renderWeeklyImpressions(data, data.deviceId),
      disableSortBy: true
    },
    {
      Header: (
        <span id="growth-header">
          <span>Growth</span>
          <UncontrolledTooltip placement="top" target="#growth-header">
            Impression growth over same period in the past
          </UncontrolledTooltip>
        </span>
      ),
      id: 'growth',
      type: 'number',
      hideTitle: true,
      accessor: data => renderGrowth(data)
    }
  ],
  traffic: ({ preferences }) => [
    {
      Header: 'Device Name',
      id: 'deviceName',
      type: 'string',
      hideTitle: true,
      accessor: d => (d.deviceName ? renderTrimmedValue(d, 'deviceName') : '--')
    },
    {
      Header: 'Detections',
      id: 'detections',
      type: 'number',
      accessor: d => d.detections
    },
    {
      Header: 'Avg Speed',
      id: 'speed',
      type: 'number',
      accessor: d => renderSpeedUnit(d.avg_speed, preferences)
    },
    {
      Header: (
        <div>
          <span>Hourly</span>
          <span className="header-subtitle">12am to 11pm</span>
        </div>
      ),
      id: 'dailyImpressions',
      type: 'number',
      accessor: data => renderDailyImpressions(data, data.deviceId),
      disableSortBy: true
    },
    {
      Header: (
        <div>
          <span>Daily</span>
          <span className="header-subtitle">Sunday to Saturday</span>
        </div>
      ),
      id: 'weeklyImpressions',
      type: 'number',
      accessor: data => renderWeeklyImpressions(data, data.deviceId),
      disableSortBy: true
    },
    {
      Header: (
        <span id="growth-header">
          <span>Growth</span>
          <UncontrolledTooltip placement="top" target="#growth-header">
            Detection growth over same period in the past
          </UncontrolledTooltip>
        </span>
      ),
      id: 'growth',
      type: 'number',
      hideTitle: true,
      accessor: data => renderGrowth(data)
    }
  ],
  cms: ({ selectedCampaign, cmsSchema }) => [
    renderCampaignMedia(selectedCampaign, cmsSchema),
    {
      Header: 'Site',
      id: 'site',
      type: 'string',
      accessor: (data, index) => renderTrimmedValue(data, cmsSchema.columns.sites, index)
    },
    {
      Header: 'Impressions',
      id: 'impressions',
      type: 'number',
      accessor: d => d.impressions
    },
    {
      Header: 'Views',
      id: 'views',
      type: 'number',
      accessor: d => d.views
    },
    {
      Header: 'Age',
      id: 'ageGroup',
      type: 'number',
      accessor: (data, index) => renderAge(data, index),
      disableSortBy: true
    },
    {
      Header: 'Gender',
      id: 'gender',
      type: 'number',
      accessor: data => renderGender(data)
    },
    {
      Header: (
        <div>
          <span>Hourly</span>
          <span className="header-subtitle">12am to 11pm</span>
        </div>
      ),
      id: 'dailyImpressions',
      type: 'number',
      accessor: (data, index) => renderDailyImpressions(data, index),
      disableSortBy: true
    },
    {
      Header: (
        <div>
          <span>Daily</span>
          <span className="header-subtitle">Sunday to Saturday</span>
        </div>
      ),
      id: 'weeklyImpressions',
      type: 'number',
      accessor: (data, index) => renderWeeklyImpressions(data, index),
      disableSortBy: true
    },
    {
      Header: (
        <span id="growth-header">
          <span>Growth</span>
          <UncontrolledTooltip placement="top" target="#growth-header">
            Impression growth over same period in the past
          </UncontrolledTooltip>
        </span>
      ),
      id: 'growth',
      type: 'number',
      hideTitle: true,
      accessor: data => renderGrowth(data)
    }
  ],
  mobile: () => [
    {
      Header: 'Device Name',
      id: 'deviceName',
      type: 'string',
      hideTitle: true,
      accessor: d => (d.deviceName ? renderTrimmedValue(d, 'deviceName', d.deviceId) : '--')
    },
    {
      Header: 'Impressions',
      id: 'impressions',
      type: 'number',
      accessor: d => d.impressions
    },
    {
      Header: 'Views',
      id: 'views',
      type: 'number',
      accessor: d => d.views
    },
    {
      Header: 'Age',
      id: 'ageGroup',
      type: 'number',
      accessor: data => renderAge(data),
      disableSortBy: true
    },
    {
      Header: 'Gender',
      id: 'gender',
      type: 'number',
      accessor: data => renderGender(data)
    },
    {
      Header: (
        <div>
          <span>Hourly</span>
          <span className="header-subtitle">12am to 11pm</span>
        </div>
      ),
      id: 'dailyImpressions',
      type: 'number',
      accessor: data => renderDailyImpressions(data, data.deviceId),
      disableSortBy: true
    },
    {
      Header: (
        <div>
          <span>Daily</span>
          <span className="header-subtitle">Sunday to Saturday</span>
        </div>
      ),
      id: 'weeklyImpressions',
      type: 'number',
      accessor: data => renderWeeklyImpressions(data, data.deviceId),
      disableSortBy: true
    },
    {
      Header: (
        <span id="growth-header">
          <span>Growth</span>
          <UncontrolledTooltip placement="top" target="#growth-header">
            Impression growth over same period in the past
          </UncontrolledTooltip>
        </span>
      ),
      id: 'growth',
      type: 'number',
      hideTitle: true,
      accessor: data => renderGrowth(data)
    }
  ]
};

const renderTrimmedValue = (data, fieldName, key) => {
  const value = data[fieldName];

  if (!value) return value;

  const trimmedValue = value.length > 20 ? `${value.slice(0, 10)}...${value.slice(-5)}` : value;

  const elementId = typeof key !== 'undefined' ? `${fieldName}-${key}` : `${fieldName}-${data[fieldName]}`;

  return (
    <div id={elementId}>
      {trimmedValue}
      <UncontrolledTooltip placement="top" target={`#${elementId}`}>
        {value}
      </UncontrolledTooltip>
    </div>
  );
};

const renderAge = (data, key) => {
  return (
    <div className="d-flex">
      <display-bar
        id={`age-${key || data.deviceId}`}
        data={JSON.stringify(data.ageGroup)}
        show-legends={false}
        config={JSON.stringify({
          isSharp: false,
          colors: ['#b9cdff'],
          hideXAxis: true,
          hideYAxis: true,
          yLabelDisabled: true,
          height: 60
        })}
        plot-options={JSON.stringify({
          markerRadius: 0,
          tooltipBackground: '#455079',
          categories: ['young', 'young adult', 'adult', 'senior']
        })}
      />
    </div>
  );
};

const renderGender = data => {
  let gender = 'female';
  let percent = '100';

  if (data.male > data.female) {
    gender = 'male';
    percent = data.male > 0 ? (100 * data.male) / (data.male + data.female) : 0;
  } else {
    percent = data.female > 0 ? (100 * data.female) / (data.male + data.female) : 0;
  }

  return (
    <div id={`gender-${data.deviceId}`} className="d-flex align-items-center">
      <img src={icons[gender]} className="gender-icon" alt="" />
      <span className="ellipsis">{percent.toFixed(2)}%</span>
      <UncontrolledTooltip placement="top" target={`#gender-${data.deviceId}`}>
        <div>
          <span className="d-flex">
            <span className="gender-legend">Male:</span>
            <b>{`${data.male}`}</b>
          </span>
          <span className="d-flex">
            <span className="gender-legend">Female:</span>
            <b>{`${data.female}`}</b>
          </span>
        </div>
      </UncontrolledTooltip>
    </div>
  );
};

// const renderDirection = data => {
//   let direction = 'angleRight';
//   let percent = '100';

//   if (data.backward > data.forward) {
//     direction = 'angleLeft';
//     percent = data.backward > 0 ? (100 * data.backward) / (data.backward + data.forward) : 0;
//   } else {
//     percent = data.forward > 0 ? (100 * data.forward) / (data.backward + data.forward) : 0;
//   }

//   return (
//     <div id={`direction-${data.deviceId}`} className="d-flex align-items-center">
//       <img src={icons[direction]} className="direction-icon" alt="" />
//       <img src={icons[direction]} className="direction-icon" alt="" />
//       <span className="ellipsis">{percent.toFixed(2)}%</span>
//       <UncontrolledTooltip placement="top" target={`#direction-${data.deviceId}`}>
//         <div>
//           <span className="d-flex">
//             <span className="direction-legend">backward:</span>
//             <b>{`${data.backward}`}</b>
//           </span>
//           <span className="d-flex">
//             <span className="direction-legend">forward:</span>
//             <b>{`${data.forward}`}</b>
//           </span>
//         </div>
//       </UncontrolledTooltip>
//     </div>
//   );
// };

const renderDailyImpressions = (data, key) => {
  return (
    <div className="d-flex">
      <display-line
        id={`daily-${key}`}
        data={JSON.stringify(data.dailyImpressions)}
        config={JSON.stringify({
          isSharp: false,
          colors: ['#c2b1fa'],
          hideXAxis: true,
          hideYAxis: true,
          yLabelDisabled: true,
          height: 60
        })}
        plot-options={JSON.stringify({
          markerRadius: 0,
          tooltipBackground: '#455079',
          categories: [
            '12am',
            '01am',
            '02am',
            '03am',
            '04am',
            '05am',
            '06am',
            '07am',
            '08am',
            '09am',
            '10am',
            '11am',
            '12pm',
            '01pm',
            '02pm',
            '03pm',
            '04pm',
            '05pm',
            '06pm',
            '07pm',
            '08pm',
            '09pm',
            '10pm',
            '11pm'
          ]
        })}
      />
    </div>
  );
};

const renderWeeklyImpressions = (data, key) => {
  return (
    <div className="d-flex">
      <display-line
        id={`weekly-${key}`}
        data={JSON.stringify(data.weeklyImpressions)}
        config={JSON.stringify({
          isSharp: false,
          colors: ['#b9cdff'],
          hideXAxis: true,
          hideYAxis: true,
          yLabelDisabled: true,
          height: 60
        })}
        plot-options={JSON.stringify({
          markerRadius: 0,
          tooltipBackground: '#455079',
          categories: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
        })}
      />
    </div>
  );
};

const renderGrowth = data => {
  const growthValue = `${data.growth.toFixed(2)}%`;
  let growthIcon = 'growthNeutral';
  let growthText = 'Neutral';

  if (data.growth > 10) {
    growthIcon = 'growthGood';
    growthText = 'Very Good';
  } else if (data.growth > 5) {
    growthIcon = 'growthRising';
    growthText = 'Rising';
  } else if (data.growth < -10) {
    growthIcon = 'growthFalling';
    growthText = 'Falling';
  } else if (data.growth < -5) {
    growthIcon = 'growthDecreasing';
    growthText = 'Decreasing';
  }

  return (
    <div id={`growth-${data.deviceId}`} className="d-flex align-items-center">
      <img src={icons[growthIcon]} className="growth-icon" alt={growthValue} />
      <span className="ellipsis growth-text">{growthText}</span>
      <UncontrolledTooltip placement="top" target={`#growth-${data.deviceId}`}>
        <div>
          <span className="d-flex">
            <b>{data.growth < 0 ? `${growthValue}` : `+${growthValue}`}</b>
            <span className="growth-legend">this period</span>
          </span>
        </div>
      </UncontrolledTooltip>
    </div>
  );
};

const renderSpeedUnit = (speed, preference) => {
  const sUnit = preferencesTemplate.projectPreferences.speedUnitOptions.find(
    speedUnit => speedUnit.id === preference.speedUnit
  );
  const convertedSpeed = speed * sUnit.conversionFactor;
  return `${convertedSpeed.toFixed(2)}${sUnit.shortName}`;
};

const renderCampaignMedia = (selectedCampaign, cmsSchema) => {
  return {
    Header: selectedCampaign ? 'Media' : 'Campaign',
    id: 'campaignName',
    type: 'string',
    hideTitle: true,
    accessor: (data, index) => {
      // Render first column as media, when there is a selected Campaign
      if (selectedCampaign) {
        return renderTrimmedValue(data, cmsSchema.columns.medias, index);
      }

      return <div className="campaign-title">{renderTrimmedValue(data, cmsSchema.columns.advertisers, index)}</div>;
    }
  };
};

export default insightsTableHeaders;
