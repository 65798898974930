import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import icons from '../../assets/icons';
import API from '../../services/api';

import './styles.scss';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isBusy: false,
      isInvalid: false,
      invalidMessage: null,
      passwordHidden: true
    };
  }

  handleInputChange = event => {
    const target = event.target;

    this.setState({
      [target.name]: target.value,
      isInvalid: false
    });
  };

  togglePassword = event => {
    event.preventDefault();
    this.setState({
      passwordHidden: !this.state.passwordHidden
    });
  };

  submit = async event => {
    event.preventDefault();

    if (this.state.isBusy) {
      return;
    }

    this.setState({
      isBusy: true,
      isInvalid: false,
      passwordHidden: true
    });

    try {
      // Moves the authenticated user to the dashboard
      const user = await API.signin(this.state.email, this.state.password);
      this.props.setLoading(true);
      this.props.setUser({ ...user, permissions: {} });
      this.props.history.replace('/');
    } catch (error) {
      const invalidMessage = (
        <div>
          You have expired licenses in your project.{' '}
          <a href="https://subscriptions.zoho.com/portal/admobilize/login" target="blank">
            Click here
          </a>{' '}
          to pay and reactivate them. If you have any questions, please contact <i>support@admobilize.com</i> for more
          details.
        </div>
      );

      this.setState({
        invalidMessage:
          error.message === 'Your account is inactive. Please, contact support@admobilize.com to get more details.'
            ? invalidMessage
            : error.message,
        isBusy: false,
        isInvalid: true
      });
    }
  };

  render() {
    return (
      <>
        <form action="#" onSubmit={this.submit} className="login-component">
          <div className="form-group d-flex flex-column">
            <label htmlFor="email" className="align-self-start">
              Email Address
            </label>
            <input
              className={`form-control form-control-lg ${this.state.isInvalid ? 'is-invalid' : ''}`}
              placeholder="Enter your email"
              type="email"
              id="email"
              name="email"
              value={this.state.email}
              onChange={this.handleInputChange}
              autoFocus
              required
              disabled={this.state.isBusy}
            />
          </div>
          <div className="form-group d-flex flex-column position-relative">
            <label htmlFor="password" className="align-self-start">
              Password
            </label>
            <input
              className={`form-control form-control-lg password-input ${this.state.isInvalid ? 'is-invalid' : ''}`}
              placeholder="Enter Password"
              type={this.state.passwordHidden ? 'password' : 'text'}
              id="password"
              name="password"
              autoComplete="password"
              value={this.state.password}
              onChange={this.handleInputChange}
              required
              disabled={this.state.isBusy}
            />
            <div className="toggle-password" title="Toggle password" onClick={this.togglePassword}>
              <img src={this.state.passwordHidden ? icons.eyeDark : icons.eyeSlashDark} alt="Toggle password" />
            </div>
            <div className="invalid-feedback text-left mt-3">{this.state.invalidMessage}</div>
          </div>
          <div className="form-group">
            <button className="btn btn-primary btn-lg btn-block" disabled={this.state.isBusy}>
              {this.state.isBusy ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                  <span className="sr-only">Loading...</span>
                </>
              ) : (
                'Log in'
              )}
            </button>
          </div>
        </form>
        <div className="text-center">
          <Link to="/forgot">Forgot Password?</Link>
        </div>
      </>
    );
  }
}

export default withRouter(Login);
