import React from 'react';
import VersionSelector from '../components/filterSelector/VersionSelector';
import SiteGroupSelector from '../components/filterSelector/SiteGroupSelector';
import SiteTypeSelector from '../components/filterSelector/SiteTypeSelector';
import utils from '.';

const filters = [
  {
    key: 'solution',
    name: 'Solution',
    type: 'dropdownSearch',
    path: 'solution',
    isAdminOnly: false,
    options: [
      {
        id: 'audience',
        label: 'Audience',
        value: `"FACEV2" OR solution = "CROWDV3"`,
        isAdminOnly: false
      },
      {
        id: 'traffic',
        label: 'Traffic',
        value: `"VEHICLERECOGNITIONV1" OR solution = "VEHICLEDETECTIONV1"`,
        isAdminOnly: false
      },
      {
        id: 'custom',
        label: 'Custom',
        value: `"VEHICLECROWD"`,
        isAdminOnly: false
      }
    ]
  },
  {
    key: 'status',
    name: 'Status',
    type: 'list',
    path: 'state.status',
    isAdminOnly: false,
    options: [
      {
        id: 'online',
        label: 'Online',
        value: `"detecting" AND state.updateTime >= CURRENT_TIMESTAMP - INTERVAL '4 minutes'`,
        isAdminOnly: false
      },
      {
        id: 'offline',
        label: 'Offline',
        value: `"offline" OR state.updateTime < CURRENT_TIMESTAMP - INTERVAL '4 minutes'`,
        isAdminOnly: false
      },
      {
        id: 'stopped',
        label: 'Stopped',
        value: `"inactive" AND (state.updateTime >= CURRENT_TIMESTAMP - INTERVAL '4 minutes')`,
        isAdminOnly: false
      },
      {
        id: 'downloadingModels',
        label: 'Downloading Models',
        value: `"downloading-models" AND (state.updateTime >= CURRENT_TIMESTAMP - INTERVAL '4 minutes')`,
        isAdminOnly: false
      },
      {
        id: 'no-camera',
        label: 'Stopped No Camera',
        value: `"no-camera" AND state.updateTime >= CURRENT_TIMESTAMP - INTERVAL '4 minutes'`,
        isAdminOnly: false
      }
    ]
  },
  {
    key: 'malos-version',
    name: 'Malos Version',
    type: 'customComponent',
    renderCustomComponent: (onSelect, filter, selected) => (
      <VersionSelector filterObject={filter} appName={'malos'} selected={selected} onSelect={onSelect} />
    ),
    path: 'state.malosVersion',
    isAdminOnly: true,
    options: []
  },
  {
    key: 'platform',
    name: 'Platform',
    type: 'list',
    path: 'state.platform',
    isAdminOnly: true,
    options: [
      {
        id: 'windows',
        label: 'Windows',
        operator: 'ILIKE',
        value: `"windows%"`,
        isAdminOnly: false
      },
      {
        id: 'linux',
        label: 'Linux',
        operator: 'ILIKE',
        value: `"linux%"`,
        isAdminOnly: false
      }
    ]
  },
  {
    key: 'adm-version',
    name: 'Admprovider Version',
    type: 'customComponent',
    renderCustomComponent: (onSelect, filter, selected) => (
      <VersionSelector filterObject={filter} appName={'admprovider'} selected={selected} onSelect={onSelect} />
    ),
    path: 'state.admproviderVersion',
    isAdminOnly: true,
    options: []
  },
  {
    key: 'sitegroups',
    name: 'Site Groups',
    type: 'customComponent',
    renderCustomComponent: (onSelect, filter, selected) => (
      <SiteGroupSelector filterObject={filter} selected={selected} onSelect={onSelect} />
    ),
    path: 'projectId',
    isAdminOnly: false,
    options: []
  },
  {
    key: 'demo',
    name: 'Demo',
    type: 'button',
    path: 'demo',
    isAdminOnly: true,
    options: [
      {
        id: 'demo',
        label: 'Demo Licenses Only',
        value: true,
        isAdminOnly: true
      },
      {
        id: 'no-demo',
        label: 'No Demos',
        value: false,
        isAdminOnly: true
      }
    ]
  },
  {
    key: 'balena',
    name: 'Balena',
    type: 'button',
    path: 'json_extract_path_text(labels, "isBalena")',
    isAdminOnly: true,
    options: [
      {
        id: 'balena',
        label: 'Balena devices',
        value: `"true"`,
        isAdminOnly: true
      }
    ]
  },
  {
    key: 'archived',
    name: 'Archived',
    type: 'list',
    path: 'archived',
    isAdminOnly: true,
    options: [
      {
        id: 'archived',
        label: 'Archived',
        value: true,
        isAdminOnly: true
      },
      {
        id: 'not-archived',
        label: 'Not Archived',
        value: false,
        isAdminOnly: true
      }
    ]
  },
  {
    key: 'has-wifi',
    name: 'Wifi',
    type: 'list',
    path: 'hasWifi',
    isAdminOnly: true,
    options: [
      {
        id: 'wifi',
        label: 'Wifi',
        value: true,
        isAdminOnly: true
      },
      {
        id: 'no-wifi',
        label: 'No Wifi',
        value: false,
        isAdminOnly: true
      }
    ]
  },
  {
    key: 'autoupdater',
    name: 'Updater',
    type: 'list',
    path: 'state.managed',
    isAdminOnly: true,
    options: [
      {
        id: 'autoupdater',
        label: 'Auto Updater',
        value: true,
        isAdminOnly: true
      },
      {
        id: 'other',
        label: 'Other',
        value: false,
        isAdminOnly: true
      }
    ]
  },
  {
    key: 'site-solution',
    name: 'Site Solution',
    type: 'dropdownSearch',
    path: 'solution',
    isAdminOnly: false,
    options: [
      {
        id: 'audience',
        label: 'Audience',
        value: `'AUDIENCE'`,
        isAdminOnly: false
      },
      {
        id: 'traffic',
        label: 'Traffic',
        value: `'TRAFFIC'`,
        isAdminOnly: false
      },
      {
        id: 'custom',
        label: 'Custom',
        value: `'CUSTOM'`,
        isAdminOnly: false
      }
    ]
  },
  {
    key: 'site-status',
    name: 'Site Status',
    type: 'list',
    path: 'status',
    isAdminOnly: false,
    options: [
      {
        id: 'enabled',
        label: 'Enabled',
        value: `'ENABLED'`,
        isAdminOnly: false
      },
      {
        id: 'disabled',
        label: 'Disabled',
        value: `'DISABLED'`,
        isAdminOnly: false
      },
      {
        id: 'not-configured',
        label: 'Not Configured',
        value: `'NOT_CONFIGURED'`,
        isAdminOnly: false
      }
    ]
  },
  {
    key: 'site-type',
    name: 'Site Type',
    type: 'customComponent',
    path: 'type.id',
    renderCustomComponent: (onSelect, filter, selected) => (
      <SiteTypeSelector filterObject={filter} selected={selected} onSelect={onSelect} />
    ),
    isAdminOnly: false,
    options: []
  },
  {
    key: 'migrated',
    name: 'Migrated',
    type: 'list',
    path: 'state.admproviderVersion',
    isAdminOnly: true,
    options: [
      {
        id: 'migrated',
        label: 'Migrated',
        operator: '>=',
        value: '3.0.0',
        type: 'version',
        isAdminOnly: true
      },
      {
        id: 'not-migrated',
        label: 'Not Migrated',
        operator: '<',
        value: '3.0.0',
        type: 'version',
        isAdminOnly: true
      }
    ]
  },
  {
    key: 'gender',
    name: 'Gender',
    type: 'list',
    path: 'gender',
    options: [
      {
        id: 'male',
        label: 'Male',
        value: 'male'
      },
      {
        id: 'female',
        label: 'Female',
        value: 'female'
      }
    ]
  },
  {
    key: 'direction',
    name: 'Direction',
    type: 'list',
    path: 'direction',
    options: [
      {
        id: 'backward',
        label: 'Backward',
        value: 'BACKWARD'
      },
      {
        id: 'forward',
        label: 'Forward',
        value: 'forward'
      }
    ]
  },
  {
    key: 'age',
    name: 'Age',
    type: 'dropdownSearch',
    path: 'age',
    options: [
      {
        id: 'young',
        label: 'Young',
        operator: '<=',
        value: 20
      },
      {
        id: 'young-adult',
        label: 'Young Adult',
        operator: '<=',
        value: 30
      },
      {
        id: 'adult',
        label: 'Adult',
        operator: '<=',
        value: 60
      },
      {
        id: 'senior',
        label: 'Senior',
        operator: '>=',
        value: 61
      }
    ]
  },
  {
    key: 'emotion',
    name: 'Emotion',
    type: 'dropdownSearch',
    path: 'emotion',
    options: [
      {
        id: 'neutral',
        label: 'Neutral',
        value: 'neutral'
      },
      {
        id: 'happy',
        label: 'Happy',
        value: 'happy'
      },
      {
        id: 'sad',
        label: 'Sad',
        value: 'sad'
      }
    ]
  },
  {
    key: 'vehicleType',
    name: 'Vehicle Type',
    type: 'dropdownSearch',
    path: 'vehicleType',
    options: [
      {
        id: 'car',
        label: 'Car',
        value: 'car'
      },
      {
        id: 'motorcycle',
        label: 'Motorcycle',
        value: 'motorcycle'
      },
      {
        id: 'bicycle',
        label: 'Bicycle',
        value: 'bicycle'
      },
      {
        id: 'truck',
        label: 'Truck',
        value: 'truck'
      },
      {
        id: 'bus',
        label: 'Bus',
        value: 'bus'
      }
    ]
  }
];

/**
 * Populates the filter options with new options
 * @param {Filter[]} filters Available filters
 * @param {string} filterName Filter where option will be replaced
 * @param {Options[]} items New option to be inserted
 * @param {string} labelField Label field
 * @param {string} valueField Value field
 * @param {string} adminField Admin field
 * @returns {Filter} Filter with new options
 */
const replaceFilterOptions = (filters, filterName, items) => {
  return filters.map(filter => {
    if (filter.name === filterName) {
      return { ...filter, options: items };
    }
    return filter;
  });
};

/**
 * Returns filter options based on user's permission
 * @param {Filter} filter Filter that contains the options
 * @param {boolean} isAdminUser User's permission
 * @returns {Filter} Options from filter according to users's permission
 */
const getFilterOptions = (filter, isAdminUser) => {
  return {
    ...filter,
    options: isAdminUser ? filter.options : filter.options.filter(option => !option.isAdminOnly)
  };
};

/**
 * Returns the filters based on user's permission
 * @param {string[]} names List of names of desired filters
 * @param {boolean} isAdminUser User's permission
 * @returns {Filter[]} List of filters based on user's permission
 */
const getFilters = (names, isAdminUser) => {
  const response = [];
  names.forEach(name => {
    const foundFilter = isAdminUser
      ? filters.find(filter => filter.name === name)
      : filters.find(filter => filter.name === name && !filter.isAdminOnly);
    if (foundFilter) {
      response.push(getFilterOptions(foundFilter, isAdminUser));
    }
  });
  return response;
};

/**
 * Special comparison for item and the filter values
 * @param {String | Number} itemValue value of the item
 * @param {String | Number} filterValue value of the filter
 * @param {String=} operator special operator on the filter
 * @returns {Boolean} the comparison between item and filter values
 */
const compareValues = (itemValue, filterValue, operator) => {
  switch (operator) {
    case '!==':
      return itemValue !== filterValue;
    case '>':
      return itemValue > filterValue;
    case '<':
      return itemValue < filterValue;
    case '>=':
      return itemValue >= filterValue;
    case '<=':
      return itemValue <= filterValue;
    default:
      return itemValue === filterValue;
  }
};

/**
 * Check if the item has the filter
 * @param {Object} item
 * @param {Filter} appliedFilter
 * @returns {boolean} if item has the filter
 */
const itemHas = (item, appliedFilter) => {
  const filterValue = appliedFilter.value;
  const itemValue = utils.get(item, appliedFilter.path);

  if (itemValue) {
    if (appliedFilter.type === 'version') {
      return compareValues(utils.compareVersion(itemValue, filterValue), 0, appliedFilter.operator);
    }
    if (typeof filterValue === 'string') {
      return compareValues(itemValue.toUpperCase(), filterValue.toUpperCase(), appliedFilter.operator);
    }
    return compareValues(itemValue, filterValue, appliedFilter.operator);
  }
  //Special case when itemValue does not exist and is type version
  if (appliedFilter.type === 'version') {
    switch (appliedFilter.operator) {
      case '!==':
        return true;
      case '<':
        return true;
      default:
        return false;
    }
  }
  return filterValue === false;
};

/**
 *
 * @param {Array} items to be filtered
 * @param {Filter[]} appliedFilters
 * @returns {Array} filtered items
 */
const filterItems = (items, appliedFilters) => {
  let filteredItems = items;
  let counters = {};

  if (appliedFilters.length > 0) {
    appliedFilters.forEach(currentFilter => {
      filteredItems = filteredItems.filter(item => {
        return itemHas(item, currentFilter);
      });
      counters[currentFilter.key] = filteredItems.length;
    });
  }
  return { filteredItems, counters };
};

const getSectionFilter = (appliedFilters, filter) => {
  return appliedFilters.filter(item => item.key === filter.key);
};

export default {
  getFilters,
  replaceFilterOptions,
  itemHas,
  filterItems,
  getSectionFilter
};
