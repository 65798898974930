const projectTypes = [
  'LOAD_PROJECTS',
  'CREATE_PROJECT',
  'SELECT_PROJECT',
  'UPDATE_SELECTED_PROJECT',
  'UPDATE_PROJECTS',
  'SET_PROJECT_TABLES',
  'CLEAR_PROJECT'
];

export default projectTypes;
