import React, { useEffect, useState } from 'react';
import CustomInput from '../../../components/customInput';
import CustomTextArea from '../../../components/customTextArea';
import EditButton from '../../../components/editButton';
import utils from '../../../utils';
import API from '../../../services/api';

const SiteGroupInfo = ({ siteGroup, editMode, setEditMode, createMode, updateSiteGroup }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [owner, setOwner] = useState(siteGroup.owner ? siteGroup.owner : '--');
  const [company, setCompany] = useState(siteGroup.companyDisplayName ? siteGroup.companyDisplayName : '--');
  const [errors, setErrors] = useState(siteGroup.errors ? siteGroup.errors : {});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    populateNameDescription();
  }, []);

  useEffect(() => {
    if (!createMode) {
      populateNameDescription();
      getAccountDetails();
      setCompany(siteGroup.companyDisplayName ? siteGroup.companyDisplayName : '--');
    }
  }, [siteGroup]);

  // Validate fields
  useEffect(() => {
    const validation = {
      'site-group-display-name': [
        { key: 'required', value: true },
        { key: 'min', value: 4 }
      ]
    };

    const errorList = {
      'site-group-display-name': utils.fieldValidation(name, validation['site-group-display-name']).join(', ')
    };
    setErrors(errorList);
    if (createMode) {
      updateSiteGroup({ ...siteGroup, displayName: name, errors: errorList });
    }
  }, [name]);

  useEffect(() => {
    if (createMode) {
      updateSiteGroup({ ...siteGroup, description });
    }
  }, [description]);

  const getAccountDetails = () => {
    if (siteGroup.ownerId) {
      setIsLoading(true);
      API.getAccountDetails([siteGroup.ownerId])
        .then(ownerList => {
          if (ownerList) {
            setOwner(ownerList[siteGroup.ownerId] ? ownerList[siteGroup.ownerId].email : '--');
          }
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  const populateNameDescription = () => {
    if (createMode) {
      setName(siteGroup.displayName ? siteGroup.displayName : '');
      setDescription(siteGroup.description ? siteGroup.description : '');
    } else {
      setName(siteGroup.displayName ? siteGroup.displayName : '--');
      setDescription(siteGroup.description ? siteGroup.description : '--');
    }
    setIsLoading(false);
  };

  const saveChanges = () => {
    const modifiedSiteGroup = { ...siteGroup, displayName: name, description };
    updateSiteGroup(modifiedSiteGroup);
    // createSiteGroup(newSiteGroup);
    setEditMode(false);
  };

  const cancelChanges = () => {
    setName(siteGroup.displayName);
    setDescription(siteGroup.description);
    setEditMode(false);
  };

  const renderContent = () => {
    return (
      <div className="info-section">
        <EditButton
          isEditable={editMode}
          renderSmallButton
          saveChanges={saveChanges}
          changeEditModeState={setEditMode}
          cancelChanges={cancelChanges}
        />
        <div className="form-group site-group-section site-group-name">
          <label>Name</label>
          <CustomInput
            id={`site-group-name-${siteGroup.displayName}`}
            value={name}
            type="text"
            isEditable={editMode}
            onChange={e => setName(e.target.value)}
            autoFocus={true}
            errorMessage={errors['site-group-display-name']}
          />
        </div>
        <div className="form-group site-group-section site-group-owner">
          <label>Owner</label>
          <CustomInput
            id={`site-group-owner-${siteGroup.owner}`}
            value={owner}
            type="text"
            isEditable={false}
            onChange={e => setOwner(e.target.value)}
          />
        </div>
        <div className="form-group site-group-section site-group-company">
          <label>Company</label>
          <CustomInput
            id={`site-group-company-${siteGroup.company}`}
            value={company}
            type="text"
            isEditable={false}
            onChange={e => setCompany(e.target.value)}
          />
        </div>
        <div className="form-group site-group-section site-group-company">
          <label>Provisioning Token</label>
          <CustomTextArea
            id={`site-group-token-${siteGroup.provisioningToken}`}
            value={siteGroup.provisioningToken}
            isEditable={false}
            onChange={() => {}}
          />
        </div>
        <div className={'form-group site-group-description'}>
          <label>Description</label>
          <CustomTextArea
            id={`site-group-description-${siteGroup.displayName}`}
            isEditable={editMode}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
        {/* {siteGroup.hasCMS &&
          <div className={'site-group-section site-group-integration'}>
            <a href="#/integrations" className="btn btn-primary">
              Integration
            </a>
          </div>
        } */}
      </div>
    );
  };

  return isLoading ? <div>Loading...</div> : renderContent();
};

export default SiteGroupInfo;
