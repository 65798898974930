import React, { useState, useEffect, useRef } from 'react';
import API from '../../services/api';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedProject } from '../../redux/projects/actions';
import { setAlert } from '../../redux/dashboard/actions';
import { getUser } from '../../redux/user/actions';
import { getSelectedSummaryDashboard } from '../../redux/templates/actions';
import { getSelectedCompany } from '../../redux/company/actions';

// Components
import components from '../../components';

// Utilities
import utils from '../../utils';
import calendar from '../../utils/calendar';

import './styles.scss';

const { DateTimeFilterBar, Widget, Loading } = components;

const SummaryContainer = ({ lastUpdated }) => {
  const [sites, setSites] = useState([]); // All sites
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [currentWidgets, setCurrentWidgets] = useState({});
  const [dashboardData, setDashboardData] = useState([]);
  const [devices, setDevices] = useState([]);

  // Flags
  const [hasTable, setHasTable] = useState(true);
  const [loadingDashboard, setLoadingDashboard] = useState(true);

  // Redux variables
  const user = useSelector(getUser);
  const project = useSelector(getSelectedProject);
  const selectedDashboard = useSelector(getSelectedSummaryDashboard);
  const company = useSelector(getSelectedCompany);

  const dispatch = useDispatch();

  const eventHandlers = useRef([]);
  const oldProjectRef = useRef();
  const oldDashboardRef = useRef();

  // Update applied filters when project changes
  useEffect(() => {
    const fetchDevices = async () => {
      setDevices([]);
      setSites([]);
      await getQueryFilter();
    };

    if (project && user && project.id !== oldProjectRef.current) {
      const startDate = !oldProjectRef.current ? calendar.getDefaultDate('start') : appliedFilters.startDate;
      const endDate = !oldProjectRef.current ? calendar.getDefaultDate('end') : appliedFilters.endDate;

      // Load Filters
      setAppliedFilters({
        startDate,
        endDate,
        timezone: project.preferences.timezone,
        interval: calendar.getIntervalById('day'),
        filterByTime: {},
        filterByWeekday: [],
        user,
        selectedDevices: devices,
        project,
        projectId: project.id,
        selectedSolution: selectedDashboard,
        // mobile data extra params
        sites: '6822072',
        startTime: startDate, // Naming convention is different on bigQuery
        endTime: endDate // Naming convention is different on bigQuery
      });
      fetchDevices();

      oldProjectRef.current = project.id;
    }
  }, [project]);

  useEffect(() => {
    setLoadingDashboard(true);
    if (!sites || sites.length === 0) return;

    loadDevices();
    setLoadingDashboard(false);
  }, [sites]);

  // Update widgets when applied filters changes
  useEffect(() => {
    const fetchDevices = async () => {
      if (selectedDashboard && selectedDashboard.id !== oldDashboardRef.current) {
        setDevices([]);
        setSites([]);
        await getQueryFilter();
        oldDashboardRef.current = selectedDashboard.id;
      }
    };

    if (selectedDashboard && selectedDashboard.id !== oldDashboardRef.current) {
      setLoadingDashboard(true);
      // Load Widgets
      const dashboard = selectedDashboard;
      let newDashboard = {};

      setDashboardData(dashboard);

      // TEST: Replace previous line by next lines to test mobile data
      // const newDashboardData = {...dashboard,
      //   rows: [
      //     ...dashboard.rows,
      //     { widgets: [
      //       {id:"mobile-generation", url:"mobileGeneration.json" },
      //       {id:"mobile-household-income", url:"mobileHouseholdIncome.json" },
      //       {id:"mobile-purchase-intent", url:"mobilePurchaseIntent.json" }
      //     ]},
      //     { widgets: [
      //       {id:"mobile-impressions", url:"mobileImpressions.json" },
      //       {id:"mobile-wordcloud", url:"mobileWordCloud.json" }
      //     ]}
      //   ]
      // };
      // setDashboardData(newDashboardData);

      // Fetch each widget from cloudStorage
      dashboard.rows.forEach(dashboardRow => {
        // TEST: Replace previous line by next line to test mobile data
        // newDashboardData.rows.forEach(dashboardRow => {
        dashboardRow.widgets.map(widgetData => {
          API.getWidget(widgetData.url)
            .then(res => {
              newDashboard = { ...newDashboard, [res.id]: res };
              setCurrentWidgets(newDashboard);
            })
            .catch(onError)
            .finally(() => {
              setLoadingDashboard(false);
            });
        });
      });

      fetchDevices();
      oldDashboardRef.current = selectedDashboard.id;
    }

    API.refreshTokenWhenNeeded();
  }, [selectedDashboard]);

  // Remove listeners on component did unmount
  useEffect(() => {
    return () => {
      eventHandlers.current.forEach(handler => {
        window.removeEventListener('requestResult', handler);
        window.removeEventListener('requestError', handler);
      });
    };
  }, []);

  const getQueryFilter = async () => {
    if (selectedDashboard) {
      const filter = encodeURIComponent(`'{"${selectedDashboard.compatibleDatasources.join('","')}"}' && datasources`);
      await loadAllSites(filter);
      // await loadDevices(filter);
    }
  };

  const loadAllSites = async filter => {
    setLoadingDashboard(true);

    if (!company || !project || !filter) return;

    const sites = await loadSites(filter);
    setSites(sites);
    setLoadingDashboard(false);
  };

  const loadSites = async (filter = '', pageToken = '') => {
    try {
      const data = await API.getSites(company.id, project.id, pageToken, filter, 'displayName, id', 200);

      const sites = data.sites;
      const nextPageToken = data.nextPageToken;

      if (nextPageToken !== '') {
        const nextPageSites = await loadSites(filter, nextPageToken);
        return [...sites, ...nextPageSites];
      }

      return sites;
    } catch (error) {
      console.error('Error loding sites:', error);
    }
  };

  const loadDevices = () => {
    const localDevices = [];
    sites.forEach(site => site.attachedDevices.forEach(device => localDevices.push(device)));
    const devicesSet = new Set([...devices, ...localDevices]);
    const deviceList = Array.from(devicesSet);

    setDevices(deviceList);

    setAppliedFilters({
      ...appliedFilters,
      selectedDevices: deviceList
    });
  };

  const onFilterSelect = payload => {
    const newAppliedFilters = utils.deepClone(appliedFilters);
    Object.keys(payload).forEach(key => {
      newAppliedFilters[key] = payload[key];
      // Update mobile data params
      if (key === 'startDate') {
        newAppliedFilters['startTime'] = payload[key];
      }
      if (key === 'endDate') {
        newAppliedFilters['endTime'] = payload[key];
      }
    });
    setAppliedFilters(newAppliedFilters);
  };

  const onError = e => {
    console.error(e);
    const error = e?.detail && e?.detail?.message ? e?.detail?.message : 'No details';
    let errorMessage = error;
    if (error && error.includes('Not found: Table')) {
      setHasTable(false);
      errorMessage = 'Table not found';
    }

    dispatch(setAlert(utils.generateAlert(errorMessage ? errorMessage : 'Something went wrong.', 'error')));
  };

  const renderEmptyMessage = () => {
    return (
      <div className="no-data-message flex-column">
        <h1>No data found!</h1>
        <p>Nothing to see here. Try choosing another Project or Solution.</p>
      </div>
    );
  };

  const renderWidgets = () => {
    if (Object.keys(currentWidgets).length === 0) {
      return null;
    }

    return dashboardData.rows.map((dashboardRow, index) => (
      <div key={`row-${index}}`} className="chart-row">
        {dashboardRow.widgets.map(widgetData => {
          return (
            <Widget
              key={`analytics-${widgetData.id}`}
              widget={currentWidgets[widgetData.id]}
              lastUpdated={lastUpdated}
              filters={appliedFilters}
              handleError={onError}
              eventHandlers={eventHandlers.current}
            />
          );
        })}
      </div>
    ));
  };

  const renderTopFilterBar = () => {
    if (!appliedFilters) {
      return null;
    }

    return (
      <div className="filter-bar">
        <DateTimeFilterBar
          appliedFilters={{
            startDate: appliedFilters.startDate,
            endDate: appliedFilters.endDate,
            interval: appliedFilters.interval
          }}
          onPeriodSelected={onFilterSelect}
        />
      </div>
    );
  };

  // Prevent rendering when there is no project or when token expires
  // This can happen after logout redirect
  if (!project) {
    return (
      <div className="no-data-message flex-column">
        <h1>No data found!</h1>
        <p>Nothing to see here. Try choosing another site group or creating a new one.</p>
      </div>
    );
  }

  return (
    <div id="summary-container" className="h-100">
      {!hasTable && renderEmptyMessage()}
      {hasTable && (
        <>
          {renderTopFilterBar()}
          <div className="scrollable-container">
            <div id="summary-chart-container">
              {loadingDashboard ? (
                <div className="h-100">
                  <Loading size={8} />
                </div>
              ) : (
                renderWidgets()
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SummaryContainer;
