import React, { useEffect, useState } from 'react';

import EditButton from '../../../../components/editButton';
import CustomInput from '../../../../components/customInput';
import CustomDropdown from '../../../../components/customDropdown';
import Timezone from '../../../../components/timezone';
import DropdownSearch from '../../../../components/dropdownSearch';
import CustomRangeInput from '../../../../components/customRangeInput';

import { getUser } from '../../../../redux/user/actions';
import { useSelector } from 'react-redux';

import utils from '../../../../utils';
import { availableCMS } from '../../../../utils/solutions';
import preferenceUtils from '../../../../utils/project/preferences';

import './styles.scss';

const Preferences = ({
  selectedProject,
  editMode,
  setEditMode,
  updateProject,
  hasButtons = true,
  isCreateMode = false,
  onChangePreferences
}) => {
  const [project, setProject] = useState({ preferences: {} });
  const [currentPreferences, setCurrentPreferences] = useState(preferenceUtils.getPreferencesTemplate());
  const [errors, setErrors] = useState({});
  const [integrations, setIntegrations] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  const currentUser = useSelector(getUser);

  const maxVehicleMultiplier = {
    car: 5,
    motorcycle: 2,
    bus: 50,
    truck: 3
  };

  useEffect(() => {
    setIntegrations(
      Object.entries(availableCMS).map(([id, value]) => ({
        name: value.name,
        id: id.toUpperCase()
      }))
    );
  }, [availableCMS]);

  useEffect(() => {
    onChangePreferences(currentPreferences);
  }, [currentPreferences]);

  useEffect(() => {
    // Update preferences when selectedProject changes
    if (selectedProject.preferences) {
      setProject({ ...selectedProject });
      setCurrentPreferences(selectedProject.preferences);
    } else {
      // Apply preference Template if there is no preferences yet
      setProject({ ...selectedProject, preferences: preferenceUtils.getPreferencesTemplate() });
    }
  }, [selectedProject]);

  // Validate fields
  useEffect(() => {
    const validations = {
      maxSessionTime: [
        { key: 'required', value: true },
        { key: 'min', value: 0 }
      ],
      maxDwellTime: [
        { key: 'required', value: true },
        { key: 'min', value: 0 }
      ],
      viewThreshold: [
        { key: 'required', value: true },
        { key: 'min', value: 0.1 }
      ],
      confidence: [
        { key: 'required', value: true },
        { key: 'min', value: 0 }
      ]
    };

    if (project) {
      const preferences = project.preferences || {};
      setErrors({
        'max-session-time': utils.fieldValidation(preferences.maxSessionTime, validations['maxSessionTime']).join(', '),
        'max-dwell-time': utils.fieldValidation(preferences.maxDwellTime, validations['maxDwellTime']).join(', '),
        'view-threshold': utils.fieldValidation(preferences.viewThreshold, validations['viewThreshold']).join(', '),
        'min-confidence-age': utils.fieldValidation(preferences.minConfidenceAge, validations['confidence']).join(', '),
        'max-confidence-age': utils.fieldValidation(preferences.maxConfidenceAge, validations['confidence']).join(', '),
        'min-confidence-emotion': utils
          .fieldValidation(preferences.minConfidenceEmotion, validations['confidence'])
          .join(', '),
        'max-confidence-emotion': utils
          .fieldValidation(preferences.maxConfidenceEmotion, validations['confidence'])
          .join(', '),
        'min-confidence-gender': utils
          .fieldValidation(preferences.minConfidenceGender, validations['confidence'])
          .join(', '),
        'max-confidence-gender': utils
          .fieldValidation(preferences.maxConfidenceGender, validations['confidence'])
          .join(', ')
      });
    }
  }, [project]);

  useEffect(() => {
    setIsAdmin(currentUser.admin);
  }, [currentUser]);

  const changeEditModeState = () => {
    setEditMode({ editMode: !editMode });
  };

  const cancelChanges = () => {
    setEditMode(false);
    setProject({ ...selectedProject });
  };

  const saveChanges = newProject => {
    for (const key in errors) {
      if (errors[key].length > 0) {
        return;
      }
    }

    // Exit edit mode if there is no buttons
    if (hasButtons) {
      setEditMode(false);
      updateProject(project);
    } else {
      updateProject(newProject);
    }
  };

  const onPreferenceChange = (key, value) => {
    const newProject = utils.deepClone(project);
    utils.set(newProject, `preferences.${key}`, value);
    setCurrentPreferences(newProject.preferences);
    setProject(newProject);

    // Save changes on update if there is no Apply Button
    if (!hasButtons) {
      saveChanges(newProject);
    }
  };

  // const getSelectedIntegration = () => {
  //   let selectedIntegration = { label: 'None', value: '' };

  //   if (project.enabledIntegrations && project.enabledIntegrations.length) {
  //     const firstEnabledIntegration = project.enabledIntegrations[0].toLowerCase();
  //     // prevent error when new integration is created on backend, but doesn't exist on local mapping
  //     const integrationLabel = availableCMS[firstEnabledIntegration]
  //       ? availableCMS[firstEnabledIntegration].name
  //       : project.enabledIntegrations[0].toLowerCase();
  //     selectedIntegration = { label: integrationLabel, value: project.enabledIntegrations[0] };
  //   }

  //   return selectedIntegration;
  // };

  return (
    <div className="project-preferences-form">
      {hasButtons && (
        <EditButton
          isEditable={editMode}
          renderSmallButton
          saveChanges={saveChanges}
          changeEditModeState={changeEditModeState}
          cancelChanges={cancelChanges}
        />
      )}

      {/* Only display Multiply feature to Admin users */}
      {isAdmin && currentPreferences.vehicleMultiplier && (
        <section className="section-container">
          <div className="section-title">
            <h4>Vehicle Multiplier</h4>
            {Object.keys(currentPreferences.vehicleMultiplier).map(factor => (
              <div className="row" key={factor}>
                <div className="form-group col-12">
                  <label>{utils.capitalize(factor)} Factor</label>
                  <CustomRangeInput
                    id={`project-${factor}-multiplier`}
                    type="range"
                    value={currentPreferences.vehicleMultiplier[factor] || 1}
                    isEditable={editMode}
                    onChange={value => onPreferenceChange(`vehicleMultiplier.${factor}`, parseInt(value))}
                    errorMessage={errors['view-threshold']}
                    step={1}
                    minValue={1}
                    maxValue={maxVehicleMultiplier[factor] || 10}
                  />
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
      <section className="section-container">
        <div className="section-title">
          <h4>Outliners Threshold</h4>
          <div className="row">
            <div className="form-group col-lg-6 col-sm-12">
              <label>Max Session (sec)</label>
              <CustomInput
                id="project-max-session-time"
                type="number"
                value={currentPreferences.maxSessionTime}
                isEditable={editMode}
                errorMessage={errors['max-session-time']}
                onChange={e => onPreferenceChange('maxSessionTime', parseInt(e.target.value))}
              />
            </div>
            <div className="form-group col-lg-6 col-sm-12">
              <label>Max Attention (sec)</label>
              <CustomInput
                id="project-max-dwell-time"
                type="number"
                value={currentPreferences.maxDwellTime}
                isEditable={editMode}
                errorMessage={errors['max-dwell-time']}
                onChange={e => onPreferenceChange('maxDwellTime', parseInt(e.target.value))}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-12">
              <label>View Threshold (sec)</label>
              <CustomRangeInput
                id="view-threshold"
                type="range"
                value={currentPreferences.viewThreshold || 0}
                isEditable={editMode}
                onChange={value => onPreferenceChange('viewThreshold', value ? parseFloat(value) : '')}
                errorMessage={errors['view-threshold']}
                step={0.1}
                minValue={0}
                maxValue={1}
                suffix={'s'}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Only display Confidence filters to Admin users */}
      {isAdmin && (
        <section className="section-container">
          <div className="section-title">
            <h4>Confidence Settings</h4>
            <div className="confidence-filters">
              <div className="row">
                <div className="form-group col-lg-6 col-sm-12">
                  <label>Min Confidence Age</label>
                  <CustomInput
                    id="project-min-confidence-age"
                    type="number"
                    value={currentPreferences.minConfidenceAge}
                    isEditable={editMode}
                    errorMessage={errors['min-confidence-age']}
                    onChange={e =>
                      onPreferenceChange('minConfidenceAge', e.target.value ? parseFloat(e.target.value) : '')
                    }
                  />
                </div>
                <div className="form-group col-lg-6 col-sm-12">
                  <label>Max Confidence Age</label>
                  <CustomInput
                    id="project-max-confidence-age"
                    type="number"
                    value={currentPreferences.maxConfidenceAge}
                    isEditable={editMode}
                    errorMessage={errors['max-confidence-age']}
                    onChange={e =>
                      onPreferenceChange('maxConfidenceAge', e.target.value ? parseFloat(e.target.value) : '')
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6 col-sm-12">
                  <label>Min Confidence Emotion</label>
                  <CustomInput
                    id="project-min-confidence-emotion"
                    type="number"
                    value={currentPreferences.minConfidenceEmotion}
                    isEditable={editMode}
                    errorMessage={errors['min-confidence-emotion']}
                    onChange={e =>
                      onPreferenceChange('minConfidenceEmotion', e.target.value ? parseFloat(e.target.value) : '')
                    }
                  />
                </div>
                <div className="form-group col-lg-6 col-sm-12">
                  <label>Max Confidence Emotion</label>
                  <CustomInput
                    id="project-max-confidence-emotion"
                    type="number"
                    value={currentPreferences.maxConfidenceEmotion}
                    errorMessage={errors['max-confidence-emotion']}
                    isEditable={editMode}
                    onChange={e =>
                      onPreferenceChange('maxConfidenceEmotion', e.target.value ? parseFloat(e.target.value) : '')
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-6 col-sm-12">
                  <label>Min Confidence Gender</label>
                  <CustomInput
                    id="project-min-confidence-gender"
                    type="number"
                    value={currentPreferences.minConfidenceGender}
                    isEditable={editMode}
                    errorMessage={errors['min-confidence-gender']}
                    onChange={e =>
                      onPreferenceChange('minConfidenceGender', e.target.value ? parseFloat(e.target.value) : '')
                    }
                  />
                </div>
                <div className="form-group col-lg-6 col-sm-12">
                  <label>Max Confidence Gender</label>
                  <CustomInput
                    id="project-max-confidence-gender"
                    type="number"
                    value={currentPreferences.maxConfidenceGender}
                    isEditable={editMode}
                    errorMessage={errors['max-confidence-gender']}
                    onChange={e =>
                      onPreferenceChange('maxConfidenceGender', e.target.value ? parseFloat(e.target.value) : '')
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="section-container">
        <div className="section-title">
          <h4>Customization</h4>
          <div className="row">
            <div className="form-group col-12">
              <label>Timezone</label>
              <Timezone
                onSelect={value => onPreferenceChange('timezone', value)}
                selected={{ label: currentPreferences.timezone, value: currentPreferences.timezone }}
                disabled={!editMode}
                leftAlign
                openUp
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-12">
              <label>Speed Unit</label>
              <CustomDropdown
                selectedItem={currentPreferences.speedUnit}
                items={preferenceUtils.projectPreferences.speedUnitOptions}
                handleSelection={e => onPreferenceChange('speedUnit', e.target.value)}
                valueField={'id'}
                displayField={'name'}
                disabled={!editMode}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Preferences;
