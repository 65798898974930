import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import types from '../../redux/types';

import CustomInput from '../customInput';
import Timezone from '../timezone';
import ReportSettings from './reportSettings';

import API from '../../services/api';
import utils from '../../utils';
import reportUtils from '../../utils/report';
import EditButton from '../editButton';
import calendar from '../../utils/calendar';

const frequencies = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' }
];

const ReportInfo = ({ companyId, report, loadReports, project }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(report.email);
  const [newReport, setNewReport] = useState(report);
  const [name, setName] = useState(report.name);
  const [timezone, setTimezone] = useState(report.timezone);
  const [isEditable, setIsEditable] = useState(false);
  const [errors, setErrors] = useState({});
  // const [reportSolution, setReportSolution] = useState({});

  useEffect(() => {
    setName(report.name);
    setEmail(report.email);
    setTimezone(report.timezone);
    setNewReport(report);
    // setReportSolution({
    //   id: String(report.table).toLowerCase()
    // });
  }, [report]);

  useEffect(() => {
    const validations = {
      email: [
        { key: 'required', value: true },
        { key: 'email', value: true }
      ],
      name: [{ key: 'required', value: true }]
    };
    setErrors({
      'email-input': utils.fieldValidation(email, validations['email']).join(', '),
      'name-input': utils.fieldValidation(name, validations['name']).join(', ')
    });
  }, [email, name]);

  const changeEditModeState = () => {
    setIsEditable({ isEditable: !isEditable });
  };

  const displayAlertMessage = (message, type) => {
    dispatch({
      type: types.SET_ALERT,
      payload: utils.generateAlert(message, type)
    });
  };

  const saveChanges = () => {
    const { frequency, seletedDay, customDay, interval } = newReport;

    // Merge errors from info and settings forms
    const errorList = {
      ...errors,
      ...newReport.errors
    };

    // Set day to send when report has custom day selected
    if (frequency === 'monthly') {
      if (!seletedDay || seletedDay === 'custom') {
        if (customDay && customDay[0] !== '') {
          newReport.daysToSend = customDay;
        }
      }
    }

    // Set correct data format for interval field
    newReport.interval = String(interval.name).toUpperCase();

    if (!utils.hasErrorsCheck(errorList)) {
      const { projectId, id } = newReport;
      const updateReportObject = reportUtils.generateUpdateReportObject({
        ...newReport,
        speedUnit: project.preferences?.speedUnit || ''
      });

      API.updateReport(companyId, projectId, id, updateReportObject)
        .then(() => {
          displayAlertMessage('Report updated successfully', 'success');
          loadReports(projectId);
        })
        .catch(error => {
          displayAlertMessage(error.message, 'error');
        })
        .finally(() => {
          setIsEditable(false);
        });
    } else {
      displayAlertMessage('Something is wrong. Any missing fields?', 'error');
    }
  };

  const cancelChanges = () => {
    setName(report.name);
    setEmail(report.email);
    setTimezone(report.timezone);
    setNewReport(report);
    setIsEditable(false);
  };

  if (report) {
    return (
      <div className="report-info">
        <EditButton
          isEditable={isEditable}
          saveChanges={saveChanges}
          cancelChanges={cancelChanges}
          renderSmallButton
          changeEditModeState={changeEditModeState}
        />

        <div className="d-flex flex-column justify-content-between input-column">
          <div className="form-group">
            <label htmlFor="name-input">Name</label>
            <CustomInput
              id="name-input"
              type="name"
              value={name}
              errorMessage={errors['name-input']}
              isEditable={isEditable}
              onChange={e => {
                const { value } = e.target;
                setNewReport({ ...newReport, name: value });
                setName(value);
              }}
            />
          </div>
        </div>

        <div className="d-flex flex-column justify-content-between input-column">
          <div className="form-group">
            <label htmlFor="email-input">E-mail</label>
            <CustomInput
              id="email-input"
              type="email"
              value={email}
              onChange={e => {
                const { value } = e.target;
                setNewReport({ ...newReport, email: value });
                setEmail(value);
              }}
              isCopyButtonEnabled={true}
              errorMessage={errors['email-input']}
              isEditable={isEditable}
            />
          </div>
        </div>

        <ReportSettings
          report={newReport}
          // solution={reportSolution}
          frequencies={frequencies}
          customIntervals={[{ id: 'minute', name: 'Minute' }, ...calendar.defaultIntervals]}
          onChange={updatedReport => setNewReport(updatedReport)}
          isEditable={isEditable}
        />

        <div className="form-group">
          <label>Timezone</label>
          <Timezone
            disabled={!isEditable}
            selected={{ label: timezone, value: timezone }}
            onSelect={tz => {
              setNewReport({ ...newReport, timezone: tz });
              setTimezone(tz);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="no-data-message">
      <h1>No data found</h1>
    </div>
  );
};

export default ReportInfo;
