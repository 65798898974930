import React, { useState, useEffect } from 'react';
import utils from '../../../utils';

import CustomCheckBox from '../../customCheckBox';

const SelectData = ({ newReport, updateReport }) => {
  const solutionSchema = utils.get(newReport, 'solution.schema') || [];
  const schemas = solutionSchema.map(schema => {
    return schema.name;
  });
  const [selectedColumns, setSelectedColumns] = useState(schemas ? [...schemas] : []);

  useEffect(() => {
    updateReport({ ...newReport, fields: selectedColumns });
  }, [selectedColumns]);

  // TODO: export to a common file
  const isSelected = columnId => {
    return selectedColumns.some(column => column === columnId);
  };

  // TODO: export to a common file
  const toggleSelection = (id, remove) => {
    if (remove) {
      const newColumns = selectedColumns.filter(column => column !== id);
      setSelectedColumns([...newColumns]);
    } else {
      setSelectedColumns([...selectedColumns, id]);
    }
  };

  return (
    <div className="select-data">
      <h3>Your CSV Report will include:</h3>
      <div className="csv-columns">
        {schemas.map(column => {
          return (
            <div className="csv-column" key={`solution-schema-${column}`}>
              <CustomCheckBox
                label={column}
                selected={isSelected(column) ? 'all' : 'none'}
                onClick={value => toggleSelection(column, value)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectData;
