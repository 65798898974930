import React, { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../redux/dashboard/actions';
import { createSolutionConfig, updateSolutionConfig } from '../../redux/presets/actions';
import { getSolutionsConfig } from '../../redux/presets/actions';

import ToolBar from '../../components/toolbar';
import ItemSelectorSingle from '../../components/itemSelectorSingle';
import Loading from '../../components/loading';
import CreatePresetModal from './createPresetModal';
import SidePanel from '../../components/sidepanel';
import SearchBox from '../../components/searchBox';
import MultipleFilters from '../../components/multipleFilters';
import Tabs from '../../components/tabs';
import Details from './details';
import DeviceList from './deviceList';
import PresetItem from './presetItem';

import utils from '../../utils';
import filterUtils from '../../utils/filters';
import API from '../../services/api';

import './styles.scss';

const PresetsContainer = () => {
  const [presets, setPresets] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showCreatePresetModal, setShowCreatePresetModal] = useState(false);
  const [creatingNewPreset, setCreatingNewPreset] = useState(false);
  const [searchingBy, setSearchingBy] = useState('');
  const [searchedPresets, setSearchedPresets] = useState([]);
  const [presetDevices, setPresetDevices] = useState({}); // List os devices using each preset
  const [deviceCount, setDeviceCount] = useState(0); // How many device using selected preset
  const [filteredPresets, setFilteredPresets] = useState([]);

  const dispatch = useDispatch();

  const solutionsConfig = useSelector(getSolutionsConfig);
  const detailsTabRef = useRef();
  const creationID = 'newPresetID';

  useEffect(() => {
    // Set the list of presets right away, then try to get device data from them
    setPresets(updatePresetsList(solutionsConfig));
    // Update list of presets after getting device data
    API.getDevicesFromPreset()
      .then(res => {
        const grouppedPresets = {};
        res.deviceConfigs.forEach(devConf => {
          if (grouppedPresets[devConf.defaultSolutionConfigId]) {
            grouppedPresets[devConf.defaultSolutionConfigId].push(devConf.name);
          } else {
            grouppedPresets[devConf.defaultSolutionConfigId] = [devConf.name];
          }
          setPresetDevices(grouppedPresets);
        });
        setPresets(updatePresetsList(solutionsConfig));
      })
      .catch(console.error);
  }, [solutionsConfig]);

  useEffect(() => {
    setFilteredPresets(presets);
    setSearchingBy('');
  }, [presets]);

  useEffect(() => {
    setSearchedPresets(onSearch());
  }, [filteredPresets]);

  useEffect(() => {
    setSearchedPresets(onSearch());
  }, [searchingBy]);

  useEffect(() => {
    // Update device count for selected preset
    if (selectedPreset) {
      setDeviceCount(presetDevices[selectedPreset.id] ? presetDevices[selectedPreset.id].length : 0);
    }
  }, [selectedPreset]);

  const updatePresetsList = newSolutionsConfig => {
    const solutionsConfigList = Object.keys(solutionsConfig)
      .map(key => newSolutionsConfig[key])
      .reduce((prev, curr) => [...prev, ...curr], []);
    return [...solutionsConfigList];
  };

  const createPresetData = async config => {
    setIsLoading(true);
    API.createSolutionConfig(config)
      .then(result => {
        dispatch(createSolutionConfig(result));
        setSelectedPreset(result);
        dispatch(setAlert(utils.generateAlert('Your new preset has been created.', 'success')));
      })
      .catch(e => {
        dispatch(
          setAlert(utils.generateAlert('Something went wrong. We had problems to create your preset.', 'error'))
        );
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const generateFilters = () => {
    const currentFilters = filterUtils.getFilters(['Solution'], true);
    currentFilters[0].path = 'solutionId';
    return currentFilters;
  };

  const updatePresetData = async (config, fields) => {
    setIsLoading(true);
    API.updateSolutionConfig(config, fields)
      .then(() => {
        dispatch(updateSolutionConfig(config));
        setSelectedPreset(config);
        dispatch(setAlert(utils.generateAlert('Your changes to preset has been saved.', 'success')));
      })
      .catch(e => {
        dispatch(
          setAlert(utils.generateAlert('Something went wrong. We had problems to save this preset changes.', 'error'))
        );
        console.error(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onPresetSelected = (preset, inEditMode) => {
    if (inEditMode && !creatingNewPreset) {
      const confirmCallback = EditionMode => onPresetSelected(preset, EditionMode);
      const cancelCallback = () => setSelectedPreset({ ...selectedPreset });

      onFocusChange(confirmCallback, cancelCallback);
    } else {
      setSelectedPreset(preset);
      setCreatingNewPreset(false);
    }
  };

  const onFocusChange = (confirmCallback, cancelCallback) => {
    const question = 'Are you sure you want to do that? Changes will not be saved.';
    const answer = window.confirm(question);

    if (answer && isEditing && detailsTabRef.current) {
      detailsTabRef.current.cancelChanges();
      confirmCallback(false);
    } else {
      cancelCallback();
    }
  };

  const onCreatePreset = (presetName, solution, copyFrom) => {
    setCreatingNewPreset(true);
    const newPreset = {
      id: creationID,
      config: copyFrom,
      displayName: presetName,
      solutionId: solution
    };
    const newPresetList = utils.deepClone(solutionsConfig);
    newPresetList[solution].push(newPreset);
    setPresets(updatePresetsList(newPresetList));
    setSelectedPreset(newPreset);
    setIsEditing(true);
  };

  const onCancel = () => {
    setSelectedPreset(null);
    setPresets(updatePresetsList(solutionsConfig));
  };

  const onSearch = () => {
    if (searchingBy === '') {
      return filteredPresets;
    }
    // Update presets based on search box content
    const searchedPresets = filteredPresets.filter(preset => {
      const presetHas = option => preset[option].toUpperCase().includes(searchingBy.toUpperCase());
      return presetHas('displayName');
    });

    return searchedPresets;
  };

  const renderPresetData = () => {
    if (!selectedPreset) {
      return (
        <div className="no-data-message">
          <h1>No data to display</h1>
        </div>
      );
    }

    return (
      <Details
        ref={detailsTabRef}
        data={selectedPreset}
        onCreate={createPresetData}
        onChange={updatePresetData}
        onCancel={onCancel}
        setEditMode={setIsEditing}
        isLoading={isLoading}
        editMode={isEditing}
        isNewPreset={selectedPreset.id === 'newPresetID'}
      />
    );
  };

  return (
    <div className="presets">
      <ToolBar
        title={'Presets'}
        icon={'preset'}
        hasButton={true}
        count={presets.length}
        buttonTitle={'Create Preset'}
        buttonPress={() => setShowCreatePresetModal(!showCreatePresetModal)}
      />
      <div className="presets-container d-flex flex-fill">
        <SidePanel
          content={
            <div className="preset-list">
              <div className="search-filter-bar">
                <SearchBox
                  className="outline"
                  placeholder="Search Device"
                  onChange={setSearchingBy}
                  searchingBy={searchingBy}
                />
                <MultipleFilters
                  items={presets}
                  filters={generateFilters()}
                  onFilter={filteredPresets => {
                    setFilteredPresets(filteredPresets);
                  }}
                  renderAsButton
                  onlyIcon
                />
              </div>
              <ItemSelectorSingle
                firstShouldBeSelected
                hideSearchBox
                hideToolbar
                renderCustomElement={(item, isItemSelected, selectCallback) => (
                  <PresetItem
                    key={item.id}
                    item={item}
                    isItemSelected={isItemSelected}
                    presetDevices={presetDevices}
                    selectCallback={selectCallback}
                  />
                )}
                selectedItem={selectedPreset}
                onItemSelected={preset => onPresetSelected(preset, isEditing)}
                items={searchedPresets}
              />
            </div>
          }
        />
        {isLoading ? (
          <Loading />
        ) : (
          <Tabs
            classes="presets-data"
            pages={{
              tabs: [
                {
                  name: 'Details',
                  content: renderPresetData()
                },
                {
                  name: `Devices (${deviceCount})`,
                  content: <DeviceList presetDevices={selectedPreset ? presetDevices[selectedPreset.id] : []} />
                }
              ]
            }}
          />
        )}
      </div>
      <CreatePresetModal
        isOpen={showCreatePresetModal}
        toggleModal={() => setShowCreatePresetModal(!showCreatePresetModal)}
        presets={presets}
        onCreate={onCreatePreset}
      />
    </div>
  );
};

export default PresetsContainer;
