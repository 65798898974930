import types from '../types';
import { setState } from '../../utils/redux';

const initialState = {
  integrations: []
};

const integrationReducer = (state = initialState, action) => {
  const { LOAD_INTEGRATIONS } = types;
  switch (action.type) {
    case LOAD_INTEGRATIONS:
      return setState(state, 'integrations', action.payload);
    default:
      return state;
  }
};

export default integrationReducer;
