import types from '../types';

export const getSolutionsConfig = state => {
  return state.presetReducer.solutionsConfig;
};

export const updateSolutionConfig = payload => {
  return { type: types.UPDATE_SOLUTION_CONFIG, payload };
};

export const createSolutionConfig = payload => {
  return { type: types.CREATE_SOLUTION_CONFIG, payload };
};
