import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setAlert } from '../../../redux/dashboard/actions';
import { getSelectedCompany } from '../../../redux/company/actions';

import components from '../../../components';
import Mapping from '../mapping';
import ImportModal from '../importModal';
const { SearchBox, DropdownSearch, CustomButton, CustomInput, EmptyState } = components;

import API from '../../../services/api';

import utils from '../../../utils';
import { initializeMapping } from '../../../utils/integrations';

const MappingsTab = ({ selectedIntegration, mappings, credentials, sites, loadCredentials, loading, loadMappings }) => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [filteredMappings, setFilteredMappings] = useState(mappings);
  const [selectedCredential, setSelectedCredential] = useState('');
  const [values, setValues] = useState(initializeMapping(selectedIntegration.mappingMetadataFields));
  const [disabledButtons, setDisabledButtons] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);

  const company = useSelector(getSelectedCompany);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(selectedSite);
  }, [selectedSite]);

  useEffect(() => {
    setFilteredMappings(mappings);
    setValues(initializeMapping(selectedIntegration.mappingMetadataFields));
  }, [mappings]);

  useEffect(() => {
    setDisabledButtons(!company.enabledCmsIntegrations.includes(selectedIntegration.vendor));
  }, [selectedIntegration]);

  const searchMappings = searchBy => {
    if (searchBy !== '') {
      setFilteredMappings(
        mappings.filter(mapping => {
          const mappingHas = option => mapping[option].toUpperCase().includes(searchBy.toUpperCase());
          return mappingHas('siteId') || mappingHas('playerId');
        })
      );
    } else {
      setFilteredMappings(mappings);
    }
  };

  const createMapping = () => {
    if (selectedCredential === '' || !selectedSite) {
      dispatch(
        setAlert(utils.generateAlert('Could not create Credential! Is something wrong with the form?', 'error'))
      );
      return;
    }

    API.createMapping(company.id, {
      credentialId: selectedCredential.id,
      siteId: selectedSite.id,
      vendor: selectedIntegration.vendor,
      metadata: values
    })
      .then(() => {
        dispatch(setAlert(utils.generateAlert('Mapping created successfuly!', 'success')));
        loadCredentials();
        loadMappings();
        setValues(initializeMapping(selectedIntegration.mappingMetadataFields));
        setShowCreateForm(false);
      })
      .catch(err => {
        console.error(err);
        dispatch(setAlert(utils.generateAlert('Could not create Mapping! Please try again.', 'error')));
      });
  };

  const updateForm = (field, val) => {
    setValues({ ...values, [field]: val });
  };

  const renderLoading = () => {
    return <p>Loading...</p>;
  };

  const renderContent = () => {
    if (!company.enabledCmsIntegrations.includes(selectedIntegration.vendor)) return renderNoCredentialState();
    return mappings.length > 0 ? renderMappingList() : renderEmptyState();
  };

  const renderMappingList = () => {
    return (
      <>
        <h2>{`List of mapped devices (${filteredMappings.length}/${mappings.length})`}</h2>
        <div>
          <SearchBox placeholder="Search by Site ID or Player ID" className="outline" onChange={searchMappings} />
          {filteredMappings.length > 0 ? (
            <>
              {filteredMappings.map(mapping => {
                const site = sites.find(site => site.id === mapping.siteId);
                const credential = credentials.find(credential => credential.id === mapping.credentialId);
                return (
                  <Mapping
                    key={mapping.id}
                    mapping={mapping}
                    site={site}
                    credentialName={credential && credential.displayName ? credential.displayName : ''}
                    loadCredentials={loadCredentials}
                    loadMappings={loadMappings}
                    selectedIntegration={selectedIntegration}
                  />
                );
              })}
            </>
          ) : (
            <div className="no-results-found">
              <p>No mappings were found</p>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderEmptyState = () => {
    return (
      <EmptyState
        icon={'deviceOutlineDark'}
        title={`You don't have any mapping.`}
        details={`Start mapping your devices by clicking "Add new Mapping".`}
      />
    );
  };

  const renderNoCredentialState = () => {
    return (
      <EmptyState
        icon={'addUser'}
        title={`You don't have any credentials for this integration.`}
        details={`Create your first credential by clicking the button "Add new Account on Accounts tab".`}
      />
    );
  };

  return (
    !loading &&
    sites && (
      <div>
        <h2>New Mapping</h2>
        {showCreateForm ? (
          <div className="account-fields">
            <div className="form-row">
              <div className="col" key="device-id">
                <div className="form-group">
                  <label>Site</label>
                  <DropdownSearch
                    title={'Search Site'}
                    selected={
                      selectedSite
                        ? { label: selectedSite.displayName, value: selectedSite.id }
                        : { label: 'Select Site', value: '' }
                    }
                    leftAlign
                    onSelect={setSelectedSite}
                    options={
                      sites
                        ? sites.map(site => {
                            return { label: site.displayName, value: site };
                          })
                        : []
                    }
                  />
                </div>
              </div>

              <div className="col" key="credential-id">
                <div className="form-group">
                  <label>Credential</label>
                  <DropdownSearch
                    title={'Search Solution Config'}
                    selected={
                      selectedCredential
                        ? { label: selectedCredential.displayName, value: selectedCredential.id }
                        : { label: 'Select credential', value: '' }
                    }
                    leftAlign
                    onSelect={value => {
                      setSelectedCredential(credentials.find(cred => cred.id === value));
                    }}
                    options={
                      credentials
                        ? credentials.map(credential => {
                            return { label: credential.displayName, value: credential.id };
                          })
                        : []
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              {selectedIntegration.mappingMetadataFields.map(field => {
                return (
                  <div className="col" key={field}>
                    <div className="form-group">
                      <label>{field}</label>
                      <CustomInput
                        id={`new-mappings-${field}`}
                        type="text"
                        value={values[field]}
                        isEditable
                        onChange={e => {
                          updateForm(field, e.target.value);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="form-row justify-content-end">
              <CustomButton
                classes="btn-danger left-button"
                title="Cancel"
                handleClick={() => {
                  setShowCreateForm(false);
                  setSelectedSite(null);
                  setSelectedCredential('');
                  setValues(initializeMapping(selectedIntegration.mappingMetadataFields));
                }}
              />
              <CustomButton classes="btn-secondary" title="Create" handleClick={createMapping} />
            </div>
          </div>
        ) : (
          <div>
            <CustomButton
              classes="btn-secondary left-button"
              title="Import CSV"
              disabled={loading || disabledButtons}
              handleClick={() => setShowImportModal(true)}
            />
            <CustomButton
              classes="btn-primary"
              title="Add Mapping"
              disabled={loading || disabledButtons}
              handleClick={() => {
                setShowCreateForm(!showCreateForm);
              }}
            />
          </div>
        )}
        <div className="horizontal-separator" />
        {loading ? renderLoading() : renderContent()}
        <ImportModal
          isOpen={showImportModal}
          customColumns={selectedIntegration.mappingMetadataFields.map(field => field)}
          integrationName={selectedIntegration.vendor}
          onUpdate={() => {
            loadCredentials();
            loadMappings();
          }}
          toggleModal={() => setShowImportModal(!showImportModal)}
        />
      </div>
    )
  );
};

export default MappingsTab;
