import types from '../types.js';

export const loadSummaryTemplates = payload => {
  return { type: types.LOAD_SUMMARY_TEMPLATES, payload };
};

export const loadAnalyticsTemplates = payload => {
  return { type: types.LOAD_ANALYTICS_TEMPLATES, payload };
};

export const getSummaryTemplates = state => {
  return state.templateReducer.summary.templates;
};
export const getAnalyticsTemplates = state => {
  return state.templateReducer.analytics.templates;
};

export const selectSummaryDashboard = payload => {
  return { type: types.SELECT_SUMMARY_DASHBOARD, payload };
};

export const getSelectedSummaryDashboard = state => {
  return state.templateReducer.summary.selected;
};

export const selectAnalyticsDashboard = payload => {
  return { type: types.SELECT_ANALYTICS_DASHBOARD, payload };
};

export const getSelectedAnalyticsDashboard = state => {
  return state.templateReducer.analytics.selected;
};
