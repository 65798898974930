import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../redux/dashboard/actions';
import {
  getProjects,
  getSelectedProject,
  selectProject,
  updateSelectedProject,
  updateProjects
} from '../../redux/projects/actions';

import ProjectSettings from './project-settings';
import DropdownSearch from '../dropdownSearch';
import CustomInput from '../customInput';
import utils from '../../utils';
import API from '../../services/api';
import icons from '../../assets/icons';
import { getSelectedCompany } from '../../redux/company/actions';
import types from '../../redux/types';
import { getUser } from '../../redux/user/actions';

const Preferences = ({ onChange }) => {
  const dispatch = useDispatch();

  const company = useSelector(getSelectedCompany);
  const projects = useSelector(getProjects);
  const selectedProject = useSelector(getSelectedProject);
  const user = useSelector(getUser);

  const [preferences, setPreferences] = useState({});

  useEffect(() => {
    if (selectedProject) {
      setPreferences({ ...selectedProject.preferences });
    }
  }, [selectedProject]);

  const onProjectSelect = async projectId => {
    try {
      const project = await API.getSiteGroup(company.id, projectId);
      const userPreferences = utils.deepClone(user.preferences);
      delete userPreferences.customWidgets;

      dispatch(selectProject(project));
      onChange({ preferences: { ...userPreferences, defaultProjectId: projectId } }, ['preferences'], err => {
        if (!err) {
          dispatch({
            type: types.UPDATE_USER,
            payload: { ...user, preferences: { ...user.preferences, defaultProjectId: projectId } }
          });
        }
      });
    } catch (error) {
      dispatch(setAlert(utils.generateAlert(error.message, 'error')));
    }

    // Change selected project
  };

  const onPreferencesChange = async preferences => {
    try {
      const project = { ...selectedProject };
      project.preferences = { ...preferences };

      const updatedProjects = projects.map(proj => {
        if (proj.id === project.id) {
          return project;
        }
        return proj;
      });

      // Send changes to Redux, API and Local Storage
      dispatch(updateSelectedProject(project));
      await API.updateSiteGroup(company.id, project, ['preferences']);
      dispatch(updateProjects(updatedProjects));

      const message = `Preferences for Project "${project.displayName}" has changed.`;
      dispatch(setAlert(utils.generateAlert(message, 'success')));
    } catch (error) {
      dispatch(setAlert(utils.generateAlert(error.message, 'error')));
    }
  };

  return (
    <div className="section">
      <div className="section-title">Site Group</div>
      <div className="section-content">
        <div className="preference-control project-selector">
          <label>Choose a Site Group</label>
          <small>
            To make changes on a Site Group is important to choose it first, then you&apos;ll be able to see what
            settings can be modified.
          </small>
          {projects && selectedProject && (
            <div>
              <DropdownSearch
                title={'Search Site Group'}
                selected={{
                  label: `${selectedProject.displayName} - ${selectedProject.id}`,
                  value: selectedProject.id
                }}
                onSelect={onProjectSelect}
                options={projects.map(proj => ({
                  label: `${proj.displayName} - ${proj.id}`,
                  value: proj.id
                }))}
              />
              {
                <>
                  <div className="form-group project-token-container">
                    <label>Project Token</label>
                    <CustomInput
                      id="project-token"
                      type="text"
                      value={selectedProject.provisioningToken}
                      isCopyButtonEnabled
                    />
                  </div>
                  <div className="info-box">
                    <img src={icons.info} alt="info-icon" />
                    <span>{`If you are looking for more information about a site group, you can click on "Go to site groups".`}</span>
                  </div>
                </>
              }
            </div>
          )}
        </div>
        {
          <ProjectSettings
            project={selectedProject ? selectedProject.id : null}
            preferences={preferences}
            onChange={onPreferencesChange}
            setAlert={message => dispatch(setAlert(message))}
          />
        }
      </div>
    </div>
  );
};

export default Preferences;
