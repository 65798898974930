// Route items
export const summary = () => ({
  key: 'summary',
  to: '/summary',
  name: 'Summary',
  icon: 'summary'
});

export const analytics = () => ({
  key: 'analytics',
  to: '#',
  name: 'Analytics',
  icon: 'analytics',
  subitems: [
    {
      key: 'charts',
      to: '/charts',
      name: 'Charts',
      icon: 'chartsOutline'
    },
    {
      key: 'insights',
      to: '/insights',
      name: 'Insights',
      icon: 'insights'
    }
  ]
});

export const management = () => {
  const subitems = [
    {
      key: 'devices',
      to: '/devices',
      name: 'Devices',
      icon: 'deviceOutlineDark'
    },
    {
      key: 'reports',
      to: '/reports',
      name: 'Reports',
      icon: 'reportOutlineDark'
    },
    {
      key: 'integrations',
      to: '/integrations',
      name: 'Integrations',
      icon: 'integrations'
    },
    {
      key: 'sites',
      to: '/sites',
      name: 'Sites',
      icon: 'location'
      // featureEnabled: 'dashboard-site-creation'
    },
    {
      key: 'site_groups',
      to: '/sitegroups',
      name: 'Site Groups',
      icon: 'projectDarkOutline'
      //TODO: REMOVE COMMENT BEFORE DEPLOY TO PRODUCTION
    }
  ];

  return {
    key: 'management',
    to: '#',
    name: 'Management',
    icon: 'settings',
    subitems
  };
};

export const advanced = () => {
  const subitems = [
    {
      key: 'config',
      to: '/advanced',
      name: 'Devices',
      icon: 'deviceOutlineDark',
      isAdminOnly: true
    },
    {
      key: 'presets',
      to: '/presets',
      name: 'Presets',
      icon: 'preset',
      isAdminOnly: true
    }
  ];

  return {
    key: 'advanced',
    to: '#',
    name: 'Advanced',
    icon: 'advancedSettings',
    isAdminOnly: true,
    subitems
  };
};
