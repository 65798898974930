import config from '../../config';
import API from '../api';

const { baseUrl } = config.coreApi;

/**
 * Creates a new CMS mapping.
 * @param {string} cid - Company ID.
 * @param {Object} mapping - Mapping data to be created.
 * @returns {Promise<Object>} Created mapping response.
 */
const createMapping = async (cid, mapping) => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/companies/${cid}/cmsmappings`;

  return fetch(url, {
    headers: API.headers,
    method: 'POST',
    body: JSON.stringify(mapping)
  }).then(res => API.generateResponse(res));
};

/**
 * Lists existing CMS mappings.
 * @param {string} cid - Company ID.
 * @param {string} page - page token (first page is defined as empty string).
 * @param {string} filter - Filter expression.
 * @param {number} pageSize - Max amount of mappings returned.
 * @param {string} orderBy - Order expression.
 * @returns {Promise<Object>} List of mappings with pagination details.
 */
const getMappings = async (cid, page = '', filter = '', pageSize = 40, orderBy = 'siteId, credentialId') => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/companies/${cid}/cmsmappings?pageSize=${pageSize}&pageToken=${page}&filter=${filter}&orderBy=${orderBy}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Updates a single CMS mapping.
 * @param {string} cid - Company ID.
 * @param {string} mapid - Mapping ID.
 * @param {Object} mapping - Mapping to be updated.
 * @param {string[]} fields - Mapping fields being updated. Comma separated.
 * @returns {Promise<Object>} Updated mapping.
 */
const updateMapping = async (cid, mapid, mapping, fields) => {
  await API.refreshTokenWhenNeeded();

  const paths = `updateMask=${fields.join(',')}`;

  const url = `${baseUrl}/companies/${cid}/cmsmappings/${mapid}?${paths}`;
  return fetch(url, {
    headers: API.headers,
    method: 'PUT',
    body: JSON.stringify(mapping)
  }).then(res => API.generateResponse(res));
};

/**
 * Deletes a CMS mapping.
 * @param {string} cid - Company ID.
 * @param {string} mapid - Mapping ID.
 * @returns {Promise<Object>} An empty object upon successful deletion.
 */
const deleteMapping = async (cid, mapid) => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/companies/${cid}/cmsmappings/${mapid}`;
  return fetch(url, {
    headers: API.headers,
    method: 'DELETE'
  }).then(res => API.generateResponse(res));
};

export { createMapping, getMappings, updateMapping, deleteMapping };
