import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getUser } from '../../../redux/user/actions';
import { getSelectedProject } from '../../../redux/projects/actions';
import { getSelectedCompany } from '../../../redux/company/actions';

import CheckBoxCard from '../../../components/checkBoxCard';

import API from '../../../services/api';

const WidgetGrid = ({ widgets, onWidgetClick }) => {
  const [hasWifiTracker, setHasWifiTracker] = useState(false);
  const [hasHeatmapDevices, setHasHeatmapDevices] = useState(false);

  const user = useSelector(getUser);
  const company = useSelector(getSelectedCompany);
  const project = useSelector(getSelectedProject);

  // Check user permission level
  const isAdmin = user && user && user.admin;

  const loadDevices = async () => {
    if (company && project) {
      await API.getDevices(company.id, '', encodeURIComponent(`projectId = '${project.id}' AND hasWifi`), 200)
        .then(result => {
          setHasWifiTracker(result.devices && result.devices.lenght > 0);
        })
        .catch(err => {
          console.error(err);
        });
      await API.getDevices(company.id, '', encodeURIComponent(`projectId = '${project.id}' AND heatmap_enabled`), 200)
        .then(result => {
          setHasHeatmapDevices(result.devices && result.devices.lenght > 0);
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  useState(() => {
    if ((project, company)) {
      loadDevices();
    }
  }, [project]);

  return (
    <div className="widget-options-container">
      {widgets.map(widget => {
        // Don't display support-only widgets if not admin
        if (!isAdmin && widget.onlyAdminEnabled) return null;

        // Don't display wifi widgets if doesn't have wifiTrack devices
        if (!hasWifiTracker && widget.wifiTracker) return null;

        // Don't display heatmap widgets if doesn't have devices with heatmap configured
        if (!hasHeatmapDevices && widget.display === 'heatmap') return null;

        return (
          <div key={widget.id}>
            <CheckBoxCard
              value={widget.id}
              image={widget.display}
              text={widget.title}
              onChange={onWidgetClick}
              defaultState={widget.selected}
            />
          </div>
        );
      })}
    </div>
  );
};

export default WidgetGrid;
