import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getSelectedCompany } from '../../redux/company/actions';
import { setAlert } from '../../redux/dashboard/actions';

import components from '../../components';
const { CustomInput, IconButton, CustomButton, Timezone } = components;
import CopyButton from '../../components/copyButton';

import API from '../../services/api';

import utils from '../../utils';
import { getCredentials, getCredentialFieldName } from '../../utils/integrations';
import icons from '../../assets/icons';

const CMSAccount = ({ credential, cmsName, credentialFields, loadCredentials }) => {
  const [active, setActive] = useState(false);
  const [values, setValues] = useState(getCredentials(credential, cmsName));
  const [isChecking, setIsChecking] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const dispatch = useDispatch();
  const company = useSelector(getSelectedCompany);

  const updateForm = (field, val) => {
    setValues({ ...values, [field]: val });
  };

  const updateCredential = () => {
    const credentialFieldName = getCredentialFieldName(credential);
    setIsUpdating(true);

    API.updateCredential(company.id, credential.id, { credential: { [credentialFieldName]: values } }, ['credential'])
      .then(() => {
        dispatch(setAlert(utils.generateAlert('Credential updated successfuly!', 'success')));
        loadCredentials();
      })
      .catch(err => {
        dispatch(setAlert(utils.generateAlert('Could not update this Credential. Try again later.', 'error')));
        console.error(err);
      })
      .finally(() => setIsUpdating(false));
  };

  const getSelectedTimezone = currentTimezone => {
    const timezone = currentTimezone ? { value: currentTimezone, label: currentTimezone } : null;
    return timezone;
  };

  return (
    <div className="account-item">
      <div className={`account-accordion-item ${active ? 'active' : ''}`}>
        <div
          className="account-title"
          onClick={() => {
            setActive(!active);
          }}
        >
          <div className="d-flex align-items-center flex-grow-1">
            <img className="user-icon" src={icons.userDark} alt="account" />
            <h3>{credential.displayName}</h3>
          </div>
          <div className="d-flex align-items-center justify-content-start mr-5">
            <img className="user-icon" src={icons.media} alt="account" />
            <h3 id={`credential-id-${credential.id}`}>{credential.id}</h3>
          </div>
          <img className="arrow-icon" src={icons.angleRight} alt="arrow" />
        </div>

        <div className="account-fields">
          <div className="form-row">
            {credentialFields &&
              credentialFields.map(field => {
                return (
                  <div className="col" key={field}>
                    <div className="form-group">
                      <label>{field}</label>
                      {field === 'pop_timezone' ? (
                        <Timezone
                          selected={getSelectedTimezone(values[field])}
                          onSelect={value => {
                            updateForm(field, value);
                          }}
                        />
                      ) : (
                        <CustomInput
                          id={`${credential.id}-${field}`}
                          type="text"
                          value={values[field]}
                          isEditable
                          onChange={e => updateForm(field, e.target.value)}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="form-row justify-content-end">
            <CustomButton
              classes="btn-secondary"
              title="Update"
              disabled={isUpdating}
              loading={isUpdating}
              handleClick={updateCredential}
            />
          </div>
        </div>
      </div>
      <div className="account-buttons">
        <IconButton
          id={`btn-connection-${credential.id}`}
          btnClasses="account-button"
          tooltip="Test Account connection"
          icon="connection"
          disabled={isChecking}
          loading={isChecking}
          onPress={() => {
            setIsChecking(true);
            API.checkCredentials({ credential: credential.credential })
              .then(res => {
                if (res.validCredentials) {
                  dispatch(setAlert(utils.generateAlert('Credentials verified!', 'success')));
                } else {
                  dispatch(setAlert(utils.generateAlert('Credentials not valid!', 'error')));
                }
              })
              .catch(err => {
                console.error(err);
                dispatch(setAlert(utils.generateAlert('Credential validation failed!', 'error')));
              })
              .finally(() => {
                setIsChecking(false);
              });
          }}
        />
        <div className="account-button">
          <CopyButton id={`credential-id-${credential.id}`} tooltip={'Copy credential'} />
        </div>
        <IconButton
          id={`btn-delete-${credential.id}`}
          btnClasses="account-button"
          tooltip="Remove Account"
          icon="del"
          onPress={() => {
            API.deleteCredential(company.id, credential.id)
              .then(() => {
                dispatch(setAlert(utils.generateAlert('Credentials deleted!', 'success')));
                loadCredentials();
              })
              .catch(err => {
                console.error(err);
                dispatch(setAlert(utils.generateAlert('Could not delete Credential!', 'error')));
              });
          }}
        />
      </div>
    </div>
  );
};

export default CMSAccount;
