import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { setAlert } from '../../redux/dashboard/actions';
import API from '../../services/api';
import credentialsStore from '../../services/credentialsStore';
import utils from '../../utils';
import CustomButton from '../customButton';
import CustomInput from '../customInput';
import './styles.scss';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inviteStatus, setInviteStatus] = useState({});
  const [loadingInvite, setLoadingInvite] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [errors, setErrors] = useState({});

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = constGetToken();
    try {
      const invitation = credentialsStore.parseJwtPayload(token);

      setEmail(invitation.email);
      getInviteStatus(token);
    } catch (error) {
      console.error(error);
      setInviteStatus({ tokenError: true });
      setLoadingInvite(false);
    }
  }, [location]);

  useEffect(() => {
    getnewUserErrors();
  }, [displayName, password, confirmPassword]);

  const getnewUserErrors = () => {
    const validations = {
      'display-name': [
        { key: 'required', value: true },
        { key: 'min', value: 4 }
      ],
      password: [
        { key: 'required', value: true },
        { key: 'min', value: 8 }
      ],
      'confirm-password': [
        { key: 'required', value: true },
        { key: 'equals', value: password }
      ]
    };

    // Don't show errors on first time
    const newErrors = {
      'display-name': utils.fieldValidation(displayName, validations['display-name']).join(', '),
      password: utils.fieldValidation(password, validations['password']).join(', '),
      'confirm-password': utils.fieldValidation(confirmPassword, validations['confirm-password']).join(', ')
    };

    setErrors(newErrors);
    return newErrors;
  };

  const confirmInvite = event => {
    event.preventDefault();
    const token = constGetToken();
    const body = {
      displayName,
      token,
      password
    };

    const newErrors = getnewUserErrors();
    if (!inviteStatus.userExists && utils.hasErrorsCheck(newErrors)) {
      dispatch(setAlert(utils.generateAlert('Something is wrong. Please check the fields.', 'error')));
      return;
    }

    setLoadingRequest(true);

    API.confirmInvite(body)
      .then(() => history.push('/logout'))
      .catch(err => console.error(err))
      .finally(() => setLoadingRequest(false));
  };

  const constGetToken = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('token');
  };

  const getInviteStatus = token => {
    // get the status from API

    API.getInviteInfo(token)
      .then(inviteStatus => {
        setInviteStatus(inviteStatus);
      })
      .catch(console.error)
      .finally(() => {
        setLoadingInvite(false);
      });
  };

  const renderUserExists = () => {
    return (
      <div>
        <div className="signup-header">
          <h2>You have been invited to join [{inviteStatus.project ? inviteStatus.project.displayName : ''}].</h2>
          <h5>To confirm you want to continue, click the button bellow.</h5>
          <span>
            Invitation email: <span className="email">{email}</span>
          </span>
        </div>
        <CustomButton
          loading={loadingRequest}
          classes="btn btn-primary"
          title="Accept Invitation"
          handleClick={confirmInvite}
        />
      </div>
    );
  };

  const renderExpiredMessage = () => {
    return (
      <div>
        <div className="signup-header">
          <h2>Unfortunately your invitation has expired.</h2>
          <p>Please contact the sender to get a new invitation.</p>
          <span>
            Invitation email: <span className="email">{email}</span>
          </span>
        </div>
      </div>
    );
  };

  const renderErrorMessage = () => {
    return (
      <div>
        <div className="signup-header">
          <h2>There is something wrong with your invitation.</h2>
          <h5>The token sent on your invitation is wrong or malformed.</h5>
          <h5>Please create an account to continue.</h5>
        </div>
      </div>
    );
  };

  const renderSignupForm = () => {
    return (
      <div>
        <div className="signup-header">
          <h2>You have been invited to join [{inviteStatus.project.displayName}].</h2>
          <h5>Please create one account to join the project.</h5>
        </div>
        <form action="#" autoComplete="off">
          <div className="signup-form row">
            <div className="form-group col-6">
              <label>Email</label>
              <CustomInput type="text" value={email} />
            </div>
            <div className="form-group col-6">
              <label>Display Name</label>
              <CustomInput
                name="display-name"
                type="text"
                value={displayName}
                autoComplete="off"
                isEditable
                errorMessage={errors['display-name']}
                onChange={e => setDisplayName(e.target.value)}
              />
            </div>
            <div className="form-group col-6">
              <label>Password</label>
              <CustomInput
                name="password"
                type="password"
                value={password}
                autoComplete="off"
                isEditable
                errorMessage={errors['password']}
                onChange={event => setPassword(event.target.value)}
              />
            </div>
            <div className="form-group col-6">
              <label>Confirm Password</label>
              <CustomInput
                name="confirm-password"
                type="password"
                value={confirmPassword}
                autoComplete="off"
                isEditable
                errorMessage={errors['confirm-password']}
                onChange={event => setConfirmPassword(event.target.value)}
              />
            </div>
          </div>
          <div className="confirm-button row">
            <CustomButton
              loading={loadingRequest}
              classes="btn btn-primary"
              disabled={utils.hasErrorsCheck(errors)}
              title="Signup and Accept Invitation"
              handleClick={confirmInvite}
            />
          </div>
        </form>
      </div>
    );
  };

  if (loadingInvite) {
    return <div>Loading</div>;
  }

  if (inviteStatus.tokenError) {
    return renderErrorMessage();
  }

  if (!inviteStatus.valid) {
    return renderExpiredMessage();
  }

  return <div className="signup-container">{inviteStatus.userExists ? renderUserExists() : renderSignupForm()}</div>;
};

export default Signup;
