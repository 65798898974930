import types from '../types.js';

export const loadIntegrations = () => {
  return { type: types.LOAD_INTEGRATIONS, payload: null };
};

export const selectIntegration = payload => {
  return { type: types.SELECT_INTEGRATION, payload };
};

export const getIntegrations = state => {
  return state.integrationReducer.integrations;
};

export const getSelectedIntegration = state => {
  return state.integrationReducer.selectedIntegration;
};
