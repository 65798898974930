import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setLoading, setAlert } from '../../redux/dashboard/actions';
import { getUser, setUser } from '../../redux/user/actions';

import components from '../../components';
import API from '../../services/api';
import NewUser from './forms/newUser';
import UpdateUser from './forms/updateUser';

import utils from '../../utils';
import './styles.scss';

const { SearchBox } = components;

const UserContainer = ({ setAlert, user }) => {
  const [state, setState] = useState({
    userId: '',
    idLookup: '',
    email: '',
    password: '',
    confirmPassword: '',
    displayName: '',
    active: true,
    isSearching: false,
    tryingToImpersonate: false,
    isSaving: false,
    firstTime: {
      displayName: true,
      email: true,
      password: true,
      confirmPassword: true
    },
    showNewUser: true,
    showUpdateUser: false
  });

  const createUser = async errors => {
    const { email, displayName, active, password } = state;
    const data = { displayName, email, password, active };

    if (!utils.hasErrorsCheck(errors)) {
      setState(prevState => ({ ...prevState, isSaving: true }));

      if (user.admin) {
        API.signUp(data)
          .then(() => {
            const alert = utils.generateAlert('User has been saved', 'success');
            setAlert(alert);
          })
          .catch(err => {
            console.log(err);
            const alert = utils.generateAlert("User couldn't be saved. Please try again", 'error');
            setAlert(alert);
            setState(prevState => ({
              ...prevState,
              firstTime: {
                displayName: false,
                email: false,
                password: false,
                confirmPassword: false
              }
            }));
          })
          .finally(() => {
            setState(prevState => ({ ...prevState, isSaving: false }));
          });
      } else {
        const alert = utils.generateAlert('You have no permission to create users', 'error');
        setAlert(alert);
        setState(prevState => ({ ...prevState, isSaving: false }));
      }
    } else {
      setAlert(utils.generateAlert('Something is wrong. Please check the fields.', 'error'));
    }
  };

  const getUpdateUserErrors = () => {
    const { displayName, email, password, confirmPassword, firstTime } = state;
    const validations = {
      'display-name': [
        { key: 'required', value: true },
        { key: 'min', value: 4 }
      ],
      email: [
        { key: 'required', value: true },
        { key: 'email', value: true }
      ],
      password: [{ key: 'min', value: 6 }],
      'confirm-password': [{ key: 'equals', value: password }]
    };
    const passwordErrors = password ? utils.fieldValidation(password, validations['password']) : [];

    if (password && email.includes('@admobilize.com')) {
      passwordErrors.push('Cannot change AdMobilize account password');
    }

    return {
      'display-name': utils.fieldValidation(displayName, validations['display-name']).join(', '),
      email: utils.fieldValidation(email, validations['email']).join(', '),
      password: firstTime.password ? '' : passwordErrors.join(', '),
      'confirm-password': password
        ? ''
        : utils.fieldValidation(confirmPassword, validations['confirm-password']).join(', ')
    };
  };

  const getnewUserErrors = () => {
    const { displayName, email, password, confirmPassword, firstTime } = state;
    const validations = {
      'display-name': [
        { key: 'required', value: true },
        { key: 'min', value: 4 }
      ],
      email: [
        { key: 'required', value: true },
        { key: 'email', value: true }
      ],
      password: [
        { key: 'required', value: true },
        { key: 'min', value: 8 }
      ],
      'confirm-password': [
        { key: 'required', value: true },
        { key: 'equals', value: password }
      ]
    };

    return {
      'display-name': firstTime.displayName
        ? ''
        : utils.fieldValidation(displayName, validations['display-name']).join(', '),
      email: firstTime.email ? '' : utils.fieldValidation(email, validations['email']).join(', '),
      password: firstTime.password ? '' : utils.fieldValidation(password, validations['password']).join(', '),
      'confirm-password': firstTime.confirmPassword
        ? ''
        : utils.fieldValidation(confirmPassword, validations['confirm-password']).join(', ')
    };
  };

  const saveChanges = async errors => {
    const { userId, email, displayName, active, password } = state;
    const data = {
      email: email,
      displayName: displayName,
      active: active
    };

    if (password) {
      data.password = password;
      data.currentPassword = password;
    }

    if (!utils.hasErrorsCheck(errors)) {
      setState(prevState => ({ ...prevState, isSaving: true }));

      if (user.admin) {
        API.updateUser(userId, data)
          .then(() => {
            const alert = utils.generateAlert('User has been updated', 'success');
            setAlert(alert);
            setState(prevState => ({ ...prevState, password: '', confirmPassword: '' }));
          })
          .catch(err => {
            console.log(err);
            const alert = utils.generateAlert("User couldn't be updated", 'error');
            setAlert(alert);
          })
          .finally(() => {
            setState(prevState => ({ ...prevState, isSaving: false }));
          });
      } else {
        const alert = utils.generateAlert('You have no permission to update this user', 'error');
        setAlert(alert);
        setState(prevState => ({ ...prevState, isSaving: false }));
      }
    } else {
      setAlert(utils.generateAlert('Something is wrong. Please check the fields.', 'error'));
    }
  };

  const updateValue = (key, value) => {
    setState(prevState => ({
      ...prevState,
      [key]: value,
      firstTime: { ...prevState.firstTime, [key]: false }
    }));
  };

  const {
    showNewUser,
    showUpdateUser,
    email,
    displayName,
    password,
    confirmPassword,
    active,
    isSearching,
    isSaving,
    userId
  } = state;

  return (
    <div id="manage-users">
      <div className="search-user">
        <label>Search User</label>
        <SearchBox
          hasSubmit={true}
          className="input-gray"
          placeholder="Email or User ID"
          onChange={value => setState(prevState => ({ ...prevState, idLookup: value }))}
          onSubmit={() => {}}
          disabledButton={isSearching}
        />
      </div>
      {showNewUser && (
        <NewUser
          email={email}
          displayName={displayName}
          active={active}
          password={password}
          confirmPassword={confirmPassword}
          errors={getnewUserErrors()}
          isSaving={isSaving}
          saveChanges={createUser}
          updateValue={updateValue}
        />
      )}
      {showUpdateUser && (
        <UpdateUser
          email={email}
          displayName={displayName}
          userId={userId}
          active={active}
          password={password}
          confirmPassword={confirmPassword}
          errors={getUpdateUserErrors()}
          isSaving={isSaving}
          saveChanges={saveChanges}
          updateValue={updateValue}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: user => dispatch(setUser(user)),
    setLoading: loading => dispatch(setLoading(loading)),
    setAlert: payload => {
      dispatch(setAlert(payload));
    }
  };
};

const mapStateToProps = state => {
  return {
    user: getUser(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
