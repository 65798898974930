import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { formatDistanceToNowStrict } from 'date-fns';

import icons from '../../assets/icons';
import { getSolutionData } from '../../utils/solutions';
import { renderStatus } from '../../utils/tables';

const renderDeviceName = device => (
  <div className="ellipsis">
    <div id={`device-name-${device.id}`}>{device.displayName}</div>
    <UncontrolledTooltip placement="top" target={`#device-name-${device.id}`}>
      {device.displayName}
    </UncontrolledTooltip>
  </div>
);

const renderSolution = (solution, id) => {
  const solutionData = getSolutionData(solution);
  const tooltip = solutionData ? solutionData.name : 'none';

  return solution ? (
    <span>
      <img id={`icon-${id}`} className="solution-icon" src={icons[solutionData.name.toUpperCase()]} alt="" />
      <UncontrolledTooltip placement="left" target={`#icon-${id}`}>
        {tooltip}
      </UncontrolledTooltip>
    </span>
  ) : (
    '--'
  );
};

const renderId = id => {
  return <div className="left-aligned-item">{id}</div>;
};

const tableHeaders = [
  {
    Header: 'Name',
    id: 'displayName',
    type: 'string',
    accessor: d => renderDeviceName(d)
  },
  {
    Header: 'Solution',
    id: 'solutions',
    type: 'string',
    accessor: d => (d.solution ? renderSolution(d.solution, d.id) : d.solution),
    disableSortBy: true
  },
  {
    Header: 'Status',
    id: 'state.status',
    type: 'string',
    disableSortBy: true,
    accessor: d => {
      return renderStatus(d.status.toLowerCase());
    }
  },
  {
    Header: 'Last Detection',
    id: 'state.lastDetectionTime',
    type: 'date',
    accessor: d =>
      d.state && d.state.lastDetectionTime ? (
        <div className="ellipsis">{formatDistanceToNowStrict(new Date(d.state.lastDetectionTime))} ago</div>
      ) : (
        '--'
      )
  },
  {
    Header: 'Version',
    id: 'state.malosVersion',
    type: 'version',
    disableSortBy: true,
    accessor: d => (d.state && d.state.malosVersion ? d.state.malosVersion : '--')
  },
  {
    Header: 'Id',
    id: 'id',
    type: 'string',
    accessor: d => renderId(d.id),
    disableSortBy: true
  }
];

export default tableHeaders;
