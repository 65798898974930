import React from 'react';
import Details from '../../components/deviceDetails/details';
import CustomButton from '../../components/customButton';

const deviceSections = (
  selectedDevice,
  solutionConfig,
  defaultConfig,
  toggleManageZones,
  onConfigChanged,
  isAdminUser
) => {
  if (!selectedDevice || !selectedDevice.config) return [];
  return [
    {
      header: 'Details',
      body: (
        <Details
          sections={['details']}
          renderOnSidebar
          currentPresetId={selectedDevice.config.selectedSolutionConfigId}
          device={
            selectedDevice
              ? {
                  ...selectedDevice,
                  config: solutionConfig,
                  defaultConfig: defaultConfig
                }
              : {}
          }
        />
      )
    },
    {
      header: 'General',
      body: (
        <Details
          sections={['general']}
          renderOnSidebar
          currentPresetId={selectedDevice.config.selectedSolutionConfigId}
          onConfigChanged={onConfigChanged}
          isAdminUser={isAdminUser}
          device={
            selectedDevice
              ? {
                  ...selectedDevice,
                  config: solutionConfig,
                  defaultConfig: defaultConfig
                }
              : {}
          }
        />
      )
    },
    {
      header: `${selectedDevice.config ? selectedDevice.config.solutionType : 'Loading...'}`,
      body: (
        <>
          <Details
            sections={['advanced']}
            renderOnSidebar
            currentPresetId={selectedDevice.config.selectedSolutionConfigId}
            onConfigChanged={onConfigChanged}
            isAdminUser={isAdminUser}
            device={
              selectedDevice
                ? {
                    ...selectedDevice,
                    config: solutionConfig,
                    defaultConfig: defaultConfig
                  }
                : {}
            }
          />
          <div className="device-input-section">
            <section className="section-container">
              <div className="section-title">
                <h4>Zones and ROIs</h4>
              </div>
              <CustomButton title="Manage Zones" classes="btn btn-secondary" handleClick={toggleManageZones} />
            </section>
          </div>
        </>
      )
    }
  ];
};

export default deviceSections;
