import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAlert } from '../../redux/dashboard/actions';
import icons from '../../assets/icons';
import components from '../../components';
import API from '../../services/api';
import utils from '../../utils';
import { getSelectedCompany } from '../../redux/company/actions';

const { CustomInput, IconButton, CustomButton } = components;

const Mapping = ({ mapping, selectedIntegration, site, credentialName, loadCredentials, loadMappings }) => {
  const [active, setActive] = useState(false);
  const [values, setValues] = useState(mapping.metadata);

  const company = useSelector(getSelectedCompany);
  const dispatch = useDispatch();

  const updateMapping = () => {
    API.updateMapping(company.id, mapping.id, { ...mapping, metadata: values }, ['metadata'])
      .then(() => {
        dispatch(setAlert(utils.generateAlert('Mapping updated successfuly!', 'success')));
        loadCredentials();
        loadMappings();
      })
      .catch(err => {
        console.error(err);
        dispatch(setAlert(utils.generateAlert('Could not update this mapping. Try again later.', 'error')));
      });
  };

  const updateForm = (field, val) => {
    setValues({ ...values, [field]: val });
  };

  // Prevent breaking application if mapping field is missing
  if (!mapping) return null;

  return (
    <div className="account-item">
      <div className={`account-accordion-item ${active ? 'active' : ''}`}>
        <div
          className="account-title"
          onClick={() => {
            setActive(!active);
          }}
        >
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex align-items-center">
              <img className="user-icon" src={icons.location} alt="account" />
              <h3>{site.displayName}</h3>
            </div>
            <div className="d-flex align-items-center">
              <img className="user-icon" src={icons.userDark} alt="account" />
              <h3>{credentialName}</h3>
            </div>
            <div className="d-flex align-items-center">
              <img className="media-icon" src={icons.media} alt="account" />
              <h3>{mapping.playerId}</h3>
            </div>
            <img className="arrow-icon" src={icons.angleRight} alt="arrow" />
          </div>
        </div>

        <div className="account-fields">
          <div className="form-row">
            <div className="col" key="device-id">
              <div className="form-group">
                <label>Site Id</label>
                <CustomInput
                  id={`site-id-${site.id}`}
                  type="text"
                  value={site.id}
                  isEditable={false}
                  onChange={() => {}}
                />
              </div>
            </div>
            {selectedIntegration.mappingMetadataFields.map(field => {
              return (
                <div className="col" key={field}>
                  <div className="form-group">
                    <label>{field}</label>
                    <CustomInput
                      id={`${mapping.id}-${field}`}
                      type="text"
                      value={values[field]}
                      isEditable
                      onChange={e => {
                        updateForm(field, e.target.value);
                      }}
                      autoFocus
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="form-row justify-content-end">
            <CustomButton classes="btn-primary" title="Update" handleClick={updateMapping} />
          </div>
        </div>
      </div>
      <div className="account-buttons">
        <IconButton
          id={`btn-delete-${mapping.id}`}
          btnClasses="account-button"
          tooltip="Remove Account"
          icon="del"
          onPress={() => {
            API.deleteMapping(company.id, mapping.id)
              .then(() => {
                loadCredentials();
                loadMappings();
                dispatch(setAlert(utils.generateAlert('Mapping deleted successfuly!', 'success')));
              })
              .catch(err => {
                dispatch(setAlert(utils.generateAlert('Could not delete this mapping. Try again later.', 'error')));
                console.error(err);
              });
          }}
        />
      </div>
    </div>
  );
};

export default Mapping;
