import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getProjects } from '../../redux/projects/actions';
import { getUser } from '../../redux/user/actions';

import components from '../../components';
import ManageUserModal from '../user/manageUserModal';
import DeviceExplorer from '../../components/deviceExplorer';
import MultipleFilters from '../../components/multipleFilters';
import DeviceComments from './tabs/deviceComments';

import API from '../../services/api';
import filterUtils from '../../utils/filters';
import { getSolutionData } from '../../utils/solutions';

import './styles.scss';

const { ManageZones, DeviceDetails, Tabs, Toolbar, SidePanel } = components;

const { Details, JsonEditor } = DeviceDetails;

const AdvancedContainer = () => {
  const [currentDevice, setCurrentDevice] = useState(null);
  const [totalDevices, setTotalDevices] = useState(0);
  const [currentPresetId, setCurrentPresetId] = useState(null);
  const [defaultConfig, setDefaultConfig] = useState(null); // Preset orginial config
  const [solutionConfig, setSolutionConfig] = useState(null); // DefaultConfig + CustomConfig
  const [currentSolution, setCurrentSolution] = useState('Audience');
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isSidepanelOpen, setIsSidepanelOpen] = useState(true);
  const [viewMode, setViewMode] = useState('list');
  const [selectedTab, setSelectedTab] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState([]);

  // const { deviceId } = useParams();
  const projects = useSelector(getProjects);
  const user = useSelector(getUser);

  useEffect(() => {
    // Close filter panel when navigate to other tab than All Devices
    if (selectedTab > 0) {
      setIsSidepanelOpen(false);
    } else {
      setIsSidepanelOpen(true);
    }
  }, [selectedTab]);

  useEffect(() => {
    setIsAdminUser(user && user.admin);
  }, [user]);

  const applyDeviceConfig = config => {
    // DefaultConfig has the preset original config
    setDefaultConfig(config.defaultConfig);

    // CustomConfig has the changes made by the user but we don't need them here

    // SolutionConfig is a merge of DefaultConfig + CustomConfig
    setSolutionConfig(config.solutionConfig);
  };

  useEffect(() => {
    fetchConfig();
  }, [currentDevice]);

  // const selectDeviceFromParam = () => {
  //   // deviceId comes from URL param
  //   if (deviceId) {
  //     const device = devices.find(dev => dev.id === deviceId);
  //     setCurrentDevice(device);
  //   } else {
  //     // Select first from the list otherwise
  //     setCurrentDevice(devices[0]);
  //   }
  // };

  const fetchConfig = () => {
    if (!currentDevice || !projects) return;

    setSolutionConfig(null);
    API.getDeviceConfig(currentDevice.companyId, currentDevice.id)
      .then(response => {
        setCurrentPresetId(response.defaultSolutionConfigId);
        applyDeviceConfig(response);
      })
      .catch(console.error);

    // Update Solution tab name with current solution
    const solutionData = getSolutionData(currentDevice.solution);
    if (solutionData) {
      setCurrentSolution(solutionData.name);
    }
  };

  const onFilter = (newFilteredDevices, currentAppliedFilters) => {
    setAppliedFilters(currentAppliedFilters);
  };

  const onDeviceCommentChange = comments => {
    // TODO: Update device list to fix comment count
    console.log(comments);
  };

  return (
    <div className="advanced-container">
      <Toolbar
        count={totalDevices > 0 ? totalDevices : null}
        title={'Devices'}
        icon={'deviceOutlineDark'}
        // hasButton
        renderFilters={
          currentDevice ? (
            <div className="selected-device">
              Selected:
              <div
                className="selected-device-name"
                onClick={() => {
                  setSelectedTab(0);
                }}
              >
                {currentDevice.displayName}
              </div>
            </div>
          ) : null
        }
        // buttonTitle={'Manage Users'}
        // buttonPress={() => setIsUserModalOpen(true)}
      />
      <div className={isSidepanelOpen ? 'advanced-content side-panel-open' : 'advanced-content side-panel-closed'}>
        <SidePanel
          content={
            <MultipleFilters
              items={[]}
              filters={filterUtils.getFilters(
                [
                  'Migrated',
                  'Updater',
                  'Balena',
                  'Site Groups',
                  'Status',
                  'Solution',
                  'Archived',
                  'Malos Version',
                  'Admprovider Version',
                  'Platform'
                ],
                true
              )}
              customAppliedFilters={appliedFilters}
              onFilter={onFilter}
            />
          }
          isOpen={isSidepanelOpen}
          onToggle={shouldOpen => {
            setIsSidepanelOpen(shouldOpen);
            if (shouldOpen) {
              setSelectedTab(0);
            }
          }}
        />
        <ManageUserModal isOpen={isUserModalOpen} toggleModal={() => setIsUserModalOpen(!isUserModalOpen)} />
        <Tabs
          classes="advanced-settings-tabs"
          onTabsChange={setSelectedTab}
          selectedTab={selectedTab}
          pages={{
            tabs: [
              {
                name: 'All Devices',
                content: (
                  <DeviceExplorer
                    currentDevice={currentDevice}
                    appliedFilters={appliedFilters}
                    viewMode={viewMode}
                    customWidth={isSidepanelOpen ? window.innerWidth - 370 : window.innerWidth}
                    onDeviceLoad={res => setTotalDevices(res.totalSize)}
                    onViewModeChange={setViewMode}
                    onSelect={setCurrentDevice}
                  />
                )
              },
              {
                name: 'Details',
                content: (
                  <Details
                    sections={['details']}
                    showPresetList
                    currentPresetId={currentPresetId}
                    onConfigChanged={fetchConfig}
                    licenses={[]}
                    isAdminUser={isAdminUser}
                    device={{
                      ...currentDevice,
                      config: solutionConfig,
                      defaultConfig: defaultConfig
                    }}
                  />
                )
              },
              {
                name: 'General',
                content: (
                  <Details
                    sections={['general']}
                    showPresetList
                    currentPresetId={currentPresetId}
                    onConfigChanged={fetchConfig}
                    isAdminUser={isAdminUser}
                    device={{
                      ...currentDevice,
                      config: solutionConfig,
                      defaultConfig: defaultConfig
                    }}
                  />
                )
              },
              {
                name: currentSolution,
                content: (
                  <Details
                    sections={['advanced']}
                    showPresetList
                    currentPresetId={currentPresetId}
                    onConfigChanged={fetchConfig}
                    isAdminUser={isAdminUser}
                    device={{
                      ...currentDevice,
                      config: solutionConfig,
                      defaultConfig: defaultConfig
                    }}
                  />
                )
              },
              {
                name: 'JSON Editor',
                content: (
                  <JsonEditor
                    rawConfig={solutionConfig}
                    currentPresetConfig={defaultConfig}
                    device={currentDevice}
                    onConfigChanged={applyDeviceConfig}
                  />
                )
              },
              {
                name: 'ROIs & Zones',
                content: (
                  <ManageZones
                    device={{
                      ...currentDevice,
                      config: solutionConfig,
                      defaultConfig: defaultConfig
                    }}
                    onConfigChanged={fetchConfig}
                    unlimitedZones={user && user.admin}
                  />
                )
              },
              {
                name:
                  currentDevice && currentDevice.deviceComments
                    ? `Comments (${currentDevice.deviceComments.length})`
                    : 'Comments (0)',
                content: (
                  <DeviceComments
                    device={currentDevice}
                    user={user.displayName}
                    onCommentUpdate={onDeviceCommentChange}
                  />
                )
              }
            ]
          }}
        />
      </div>
    </div>
  );
};

export default AdvancedContainer;
