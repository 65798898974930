import React from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import CustomButton from '../../../../components/customButton';
import './styles.scss';

const RemoveUser = ({ toggle, modalOpen, project, removeUsers, selectedUsers }) => {
  return (
    <Modal className="modal-add-user" isOpen={modalOpen} fade={false} toggle={toggle} centered>
      <div className="modal-header">
        <h2>{`Remove ${selectedUsers.length} ${selectedUsers.length > 1 ? 'users' : 'user'}?`}</h2>
        <h4>{`Are you sure you want to remove ${selectedUsers.length > 1 ? 'those users' : 'an user'} from ${
          project.displayName
        }?`}</h4>
      </div>
      <ModalBody>
        <div className="remove-users-modal-wrapper">
          <div className="remove-users-modal">
            {selectedUsers.map(user => {
              return (
                <div className="user-data-row" key={user.id}>
                  <div className="user-name">{user.displayName}</div>
                  <div className="user-email">{user.email}</div>
                </div>
              );
            })}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="button-container">
          <CustomButton
            classes={'btn-danger'}
            title={'Remove'}
            handleClick={() => {
              toggle();
              removeUsers(selectedUsers);
            }}
          />
          <CustomButton classes={'btn-secondary'} title={'Cancel'} handleClick={toggle} />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RemoveUser;
