import React, { useEffect, useState } from 'react';

import Accordion from '../accordion';
import CustomButton from '../customButton';
import MultiSelector from '../multiSelector';
import ActionButtons from '../actionButtons';

import utils from '../../utils';
import API from '../../services/api';

import './styles.scss';
import { useSelector } from 'react-redux';
import { getSelectedProject } from '../../redux/projects/actions';
import { getSelectedCompany } from '../../redux/company/actions';
import reportUtils from '../../utils/report';
import { getCmsInfo } from '../../utils/integrations';
import { getIntegrations } from '../../redux/integration/actions';

const AnalyticsSelector = ({ sites, selectedDashboard, devices, appliedFilters, onSelection, cmsIntegration }) => {
  const [medias, setMedias] = useState([]);
  const [advertisers, setAdvertisers] = useState([]);
  const [isLoadingMedias, setIsLoadingMedias] = useState(false);
  const [isLoadingAdvertisers, setIsLoadingAdvertisers] = useState(false);

  const [localFilters, setLocalFilters] = useState({
    selectedDevices: devices,
    selectedSites: sites,
    selectedAdvertisers: [],
    selectedMedias: []
  });

  const [deviceQuickView, setDeviceQuickView] = useState('');
  const [cms, setCms] = useState(null);

  const project = useSelector(getSelectedProject);
  const company = useSelector(getSelectedCompany);
  const integrations = useSelector(getIntegrations);

  useEffect(() => {
    if (cmsIntegration) {
      const cmsCandidate = getCmsInfo(cmsIntegration, integrations);
      setCms(cmsCandidate);
    } else {
      // Clear media and advertiser data if current solution is not
      setCms(null);
      mediaCallback([]);
      advertisersCallback([]);
    }
  }, [selectedDashboard]);

  useEffect(() => {
    if (cms) {
      setIsLoadingAdvertisers(true);
      setIsLoadingMedias(true);
      fetchCMSFilters('advertisers', cms.sourceTable, cms.popColumnMappings, advertisersCallback);
    }
  }, [cms, appliedFilters.startDate, appliedFilters.startDate]);

  useEffect(() => {
    if (cms) {
      fetchCMSFilters('medias', cms.sourceTable, cms.popColumnMappings, mediaCallback);
    }
  }, [advertisers]);

  const isButtonDisabled = (previousItems, currentItems) => {
    return currentItems.length === 0 || utils.compareArrays(previousItems, currentItems);
  };

  const mediaCallback = newMedias => {
    onSelection({ selectedMedias: newMedias });
    setMedias(newMedias);
    setLocalFilters({ ...localFilters, selectedMedias: newMedias });
    setIsLoadingMedias(false);
  };

  const advertisersCallback = newAdvertisers => {
    onSelection({ selectedAdvertisers: newAdvertisers });
    setAdvertisers(newAdvertisers);
    setLocalFilters({ ...localFilters, selectedAdvertisers: newAdvertisers });
    setIsLoadingAdvertisers(false);
  };

  const fetchCMSFilters = (fieldName, table, columns, callback) => {
    const field = columns[fieldName];
    const data = reportUtils.generateCMSQueryObject(field, appliedFilters.startDate, appliedFilters.endDate);

    API.getCMSData(company.id, project.id, table, data)
      .then(res => {
        const datasets = res.datasets;
        if (datasets && datasets.length > 0) {
          let cmsData = [];
          const datasetValue = datasets[0].value || [];
          if (typeof datasetValue === 'string') {
            cmsData = [{ id: `${fieldName}-0`, name: utils.escapeQuotes(datasetValue) }];
          } else {
            cmsData = datasetValue.sort(utils.compareStrings).map((value, index) => {
              return { id: `${fieldName}-${index}`, name: value };
            });
          }

          // TODO: Look for changes on CMS filter
          // utils.getListIntersection(this.state[selectedCMSData], cmsData, fieldName, this.props.setAlert)
          callback(cmsData);
        } else {
          callback([]);
        }
      })
      .catch(err => {
        console.error(err);
        this.onError(err);
      });
  };

  const onQuickView = item => {
    if (deviceQuickView === item.id) {
      onSelection(localFilters);
      setDeviceQuickView('');
    } else {
      onSelection({ selectedDevices: item.attachedDevices, selectedSites: [item] });
      setDeviceQuickView(item.id);
    }
  };

  const populateDevices = selectedSites => {
    const localDevices = [...new Set(selectedSites.reduce((acc, site) => acc.concat(site.attachedDevices), []))];
    setLocalFilters({ ...localFilters, selectedDevices: localDevices, selectedSites: selectedSites });
  };

  const renderActionButtons = device => {
    const styles = `action-buttons ${deviceQuickView === device.id ? 'is-active' : ''}`;
    return (
      <>
        <div className={styles}>
          <ActionButtons
            buttons={[
              {
                id: `quick-data-${device.id}`,
                icon: deviceQuickView === device.id ? 'soloDark' : 'eyeDark',
                key: 'quick-data',
                tooltip: 'See the data',
                onPress: () => onQuickView(device)
              }
            ]}
          />
        </div>
      </>
    );
  };

  let sections = [
    {
      header: `Sites (${
        appliedFilters?.selectedSites?.length > 0 ? appliedFilters?.selectedSites?.length : sites?.length
      }/${sites?.length})`,
      body: (
        <>
          <MultiSelector
            items={sites}
            onSelect={value => {
              populateDevices(value);
            }}
            displayField="displayName"
            emptyMessage="No Sites found"
            customSearchPlaceHolder="Search Site"
            sortField="displayName"
            renderActionButtons={renderActionButtons}
          />
          {sites.length > 0 && (
            <CustomButton
              handleClick={() => {
                onSelection(localFilters);
                setDeviceQuickView('');
              }}
              classes="btn-primary btn-slim"
              title="Update Analytics"
              disabled={isButtonDisabled(appliedFilters.selectedSites, localFilters.selectedSites)}
            />
          )}
        </>
      )
    },
    {
      header: isLoadingAdvertisers
        ? 'Loading Advertisers...'
        : `Campaigns/Advertisers (${appliedFilters.selectedAdvertisers.length}/${advertisers.length})`,
      body: (
        <>
          <MultiSelector
            items={advertisers}
            onSelect={value => {
              setLocalFilters({ ...localFilters, selectedAdvertisers: value });
            }}
            displayField="name"
            emptyMessage="No Campaigns found for this period"
            customSearchPlaceHolder="Search Campaign"
            sortField="name"
          />
          {advertisers.length > 0 && (
            <CustomButton
              handleClick={() => onSelection(localFilters)}
              classes="btn-primary btn-slim"
              title="Apply"
              disabled={isButtonDisabled(appliedFilters.selectedAdvertisers, localFilters.selectedAdvertisers)}
            />
          )}
        </>
      )
    },
    {
      header: isLoadingMedias
        ? 'Loading Medias...'
        : `Media (${appliedFilters.selectedMedias.length}/${medias.length})`,
      body: (
        <>
          <MultiSelector
            items={medias}
            onSelect={value => {
              setLocalFilters({ ...localFilters, selectedMedias: value });
            }}
            displayField="name"
            emptyMessage="No Medias found for this period"
            customSearchPlaceHolder="Search Media"
            sortField="name"
          />
          {medias.length > 0 && (
            <CustomButton
              handleClick={() => onSelection(localFilters)}
              classes="btn-primary btn-slim"
              title="Apply"
              disabled={isButtonDisabled(appliedFilters.selectedMedias, localFilters.selectedMedias)}
            />
          )}
        </>
      )
    }
  ];

  if (!cmsIntegration) {
    // Remove Advertisers/Media section if CMS toggle is disabled
    sections = sections.slice(0, sections.length - 2);
  }

  return (
    <div className={`side-menu ${cmsIntegration ? 'cms-mode' : ''}`}>
      <Accordion items={sections}></Accordion>
    </div>
  );
};

export default AnalyticsSelector;
