import React, { useState } from 'react';

import containers from '../../containers';
import components from '../../components';
import ReloadButton from '../../components/reloadButton';
import TimezoneInfo from '../../components/timezoneInfo';
import DashboardSelector from '../../components/dashboardSelector/DashboardSelector';

const { InsightsContainer } = containers;
const { Header, ProjectSelector, NotificationTools } = components;

const InsightScreen = () => {
  const [lastUpdated, setLastUpdated] = useState('');

  return (
    <div className="d-flex flex-column flex-fill">
      <Header
        title={'Analytics'}
        filters={
          <div className="d-flex">
            <ProjectSelector />
            <DashboardSelector collection={'ANALYTICS'} />
          </div>
        }
        icon={'analyticsDark'}
        actions={[
          { type: 'reactComponent', value: <TimezoneInfo key="timezone-info" /> },
          {
            type: 'reactComponent',
            value: <ReloadButton key="reload-btn" contentName="Insights data" lastUpdatedCB={setLastUpdated} />
          },
          { type: 'reactComponent', value: <NotificationTools key="notification-btn" /> }
        ]}
      />
      <InsightsContainer lastUpdated={lastUpdated} />
    </div>
  );
};

export default InsightScreen;
