import { combineReducers } from 'redux';
import companyReducer from './company/reducer';
import dashboardReducer from './dashboard/reducer';
import integrationReducer from './integration/reducer';
import notificationReducer from './notification/reducer';
import presetReducer from './presets/reducer';
import projectReducer from './projects/reducer';
import templateReducer from './templates/reducer';
import userReducer from './user/reducer';

const dashboard = combineReducers({
  companyReducer,
  dashboardReducer,
  integrationReducer,
  notificationReducer,
  presetReducer,
  projectReducer,
  templateReducer,
  userReducer
});

export default dashboard;
