// Common
import React, { useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedProject } from '../../redux/projects/actions';
import types from '../../redux/types';

// Components
import ActionButtons from '../../components/actionButtons';
import sendCommand from './sendCommand';
import utils from '../../utils';

const BulkActionButtons = ({ devices, isAdvertiserUser }) => {
  const dispatch = useDispatch();

  // Status can be: Online, Updating, Offline, Stopped
  const [isCommandRunning, setIsCommandRunning] = useState(false);

  const project = useSelector(getSelectedProject);

  //Callback functions definitions.
  const onSuccess = command => {
    // if (command === 'start') {
    //   setDeviceStatus('Online');
    // } else if (command === 'stop') {
    //   setDeviceStatus('Stopped');
    // }
    dispatch({
      type: types.SET_ALERT,
      payload: utils.generateAlert(`Command ${command} sent`, 'success')
    });
  };

  const onError = error => {
    dispatch({
      type: types.SET_ALERT,
      payload: utils.generateAlert(error.message, 'error')
    });
  };

  // List of available commands
  const availableCommands = [
    {
      id: 'restart-bulk',
      key: 'restart',
      icon: 'cmdRestart',
      tooltip: 'Restart Detection',
      disabled: isCommandRunning,
      onPress: () => sendCommand('restart', devices, project.id, setIsCommandRunning, onSuccess, onError)
    },
    {
      id: 'start-bulk',
      key: 'start',
      icon: 'cmdStart',
      tooltip: 'Start Detection',
      disabled: isCommandRunning,
      onPress: () => sendCommand('start', devices, project.id, setIsCommandRunning, onSuccess, onError)
    },
    {
      id: 'stop-bulk',
      key: 'stop',
      icon: 'cmdStop',
      tooltip: 'Stop Detection',
      disabled: isCommandRunning,
      onPress: () => sendCommand('stop', devices, project.id, setIsCommandRunning, onSuccess, onError)
    }
  ];

  /**
   * Set device status
   * @param {string} newStatus status to be set
   */
  // const setDeviceStatus = newStatus => {
  //   if (newStatus && devices && devices.length > 0) {
  //     // For performance reasons many dispaches should
  //     // be done by a batch operation to avoid many re-renders
  //     batch(() => {
  //       devices.forEach(device => {
  //         dispatch({
  //           type: types.UPDATE_DEVICE_STATUS,
  //           payload: { deviceId: device.id, newStatus }
  //         });
  //       });
  //     });
  //   }
  // };

  return <div className="commands-list">{!isAdvertiserUser && <ActionButtons buttons={availableCommands} />}</div>;
};

export default BulkActionButtons;
