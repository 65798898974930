export const constraints = {
  dashboard: 'devicemanagementapi.projects.update',
  support: 'authapi.accounts.impersonate',
  advertiser: [
    'iam.permissions.get',
    'iam.permissions.list',
    'iam.roles.get',
    'iam.roles.list',
    'iam.policies.get',
    'queryapi.datasets.queryTable',
    'queryapi.datasets.listTables',
    'queryapi.datasets.getTableSchema',
    'queryapi.datasets.getTableReport',
    'queryapi.jobs.create',
    'queryapi.jobs.get',
    'queryapi.jobs.getResults',
    'queryapi.jobs.exportResults',
    'queryapi.datasets.create',
    'authapi.accounts.update',
    'authapi.accounts.show',
    'iam.policies.getResources',
    'iam.ancestors.listResource',
    'queryapi.datasets.getTableData',
    'queryapi.datasets.listTableReport',
    'queryapi.datasets.pauseTableReport',
    'queryapi.datasets.restartTableReport',
    'queryapi.datasets.cancelTableReport',
    'cmsintegrationsapi.integrations.list',
    'cmsintegrationsapi.reports.create',
    'devicemanagementapi.devices.list'
  ]
};

export const roles = [
  { id: 'admobilize.viewer', name: 'Data Viewer' },
  { id: 'admobilize.editor', name: 'Dashboard User' },
  { id: 'admobilize.owner', name: 'Dashboard User' }
];

export const getRoleName = role => {
  const roleFound = roles.find(innerRole => innerRole.id === role);
  return roleFound ? roleFound.name : 'unknown';
};
