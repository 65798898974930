import React, { useState, useEffect, useRef } from 'react';
import utils from '../../utils';
import Loading from '../loading';
import { parseForTable } from '../../utils/presetModels';
import { useSelector, useDispatch } from 'react-redux';
import { getSolutionsConfig } from '../../redux/presets/actions';
import { setAlert } from '../../redux/dashboard/actions';
import DropdownSearch from '../dropdownSearch';
import PresetCompareModal from '../presetCompareModal';
import DeviceInputs from '../deviceInput';
import EditButton from '../editButton';
import parse from '../../utils/device/parse';
import API from '../../services/api';

const Details = ({
  device,
  currentPresetId,
  showPresetList,
  sections,
  renderOnSidebar,
  onConfigChanged,
  isAdminUser
}) => {
  // Make a device copy to avoid overwriting the original before saving changes
  const [isEditable, setIsEditable] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [applyingPreset, setApplyingPreset] = useState(false);
  const [isConfigLoaded, setIsConfigLoaded] = useState(false);
  const [deviceCopy, setDeviceCopy] = useState(utils.deepClone(device));
  const [oldSettings, setOldSettings] = useState({});
  const [newSettings, setNewSettings] = useState({});
  const [modalProps, setModalProps] = useState({
    title: `Would you like to reset this device to "${selectedPreset.displayName}" default settings?`,
    subtitle: `This will overwrite your custom GENERAL and ADVANCED settings.`,
    configs: {
      old: {
        title: 'Custom Value',
        label: 'Your customized values'
      },
      new: {
        title: 'Preset Value',
        label: selectedPreset.displayName || ''
      }
    }
  });

  const prevDeviceId = useRef();

  // Redux state
  const solutionsConfig = useSelector(getSolutionsConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    // If anything on device has changed, and there is a selected device
    if (device) {
      const currentDevice = utils.deepClone(device);

      // Update preset info
      if (showPresetList && solutionsConfig) {
        const selectedPreset = getCurrentPreset();
        setSelectedPreset(selectedPreset);
      }

      // Update isConfigLoaded flag to control loading and prevent errors
      setIsConfigLoaded(device.config && Object.keys(device.config).length > 0);

      // Update OldSettings reference to be shown on Comparison modal
      setOldSettings(parse.presetToDeviceConfig(device, device.solution).settings);

      // If current device selected has changed
      if (device.id !== prevDeviceId.current) {
        // Disable edit mode
        setIsEditable(false);
      }

      setDeviceCopy(currentDevice);
      prevDeviceId.current = device.id;
    }
  }, [device]);

  useEffect(() => {
    const parsedSettings = parse.presetToDeviceConfig(deviceCopy, device.solution);

    setNewSettings(parsedSettings.settings);
  }, [deviceCopy]);

  useEffect(() => {
    setIsEditable(false);
  }, [sections]);

  // Change Device form inputs from read to edit mode
  const changeEditModeState = () => {
    setIsEditable(!isEditable);
  };

  const updateSolutionConfig = () => {
    // This function will be called if device settings has changed or a preset is applied/reseted

    // Default case is changing device settings only.
    // In this case, parse customConfig to expected format before patch
    let parsedData = parse.parseDeviceToServerConfig(deviceCopy);
    let fields = ['customConfig'];

    // But if the changes are due to preset actions, use selected preset as part of requests params
    if (applyingPreset) {
      fields = ['customConfig', 'defaultSolutionConfigId'];
      parsedData = {
        customConfig: selectedPreset.config,
        name: deviceCopy.name,
        defaultSolutionConfigId: selectedPreset.id
      };
    }

    // Send changes to Device Management API
    API.updateDeviceConfig(deviceCopy.companyId, deviceCopy.id, parsedData, fields)
      .then(updateResponse => {
        // Send updated config to parent if needed
        if (onConfigChanged) {
          onConfigChanged(updateResponse);
        }
        dispatch(setAlert(utils.generateAlert('Device updated successfully!', 'success')));
        setIsEditable(false);
      })
      .catch(err => {
        dispatch(setAlert(utils.generateAlert('Something went wrong. Please try again!', 'error')));
        console.error(err);
      })
      .finally(() => {
        setApplyingPreset(false);
      });
  };

  const getCurrentPreset = () => {
    if (!solutionsConfig || !device) return {};
    const presetList = solutionsConfig[device.solution];

    if (presetList && device.config) {
      return presetList.find(preset => preset.id === currentPresetId) || {};
    }

    return {};
  };

  const handlePresetSelector = (presetId, reset = false) => {
    // Reset default preset will keep the current selected
    let presetFound = selectedPreset;

    const currentSelectedPresetName = getCurrentPreset().displayName;
    let title = `Are you sure you want to reset default settings from "${currentSelectedPresetName}" preset?`;
    let subtitle = `Be careful with your custom configs. ${getPresetInfoMessage()} settings will be lost.`;
    let configOldTitle = 'Custom Config';
    let configNewTitle = 'Default Config';

    // otherwise we need to get selected from id
    if (!reset) {
      presetFound = solutionsConfig[device.solution].find(preset => presetId === preset.id) || {};

      setSelectedPreset(presetFound);
      title = 'Are you sure you want to apply this preset?';
      subtitle = `You are changing from "${currentSelectedPresetName}" to "${presetFound.displayName}"`;
      configOldTitle = 'Current Preset';
      configNewTitle = 'New Preset';
    }

    setApplyingPreset(true);

    const parsedSettings = parse.presetToDeviceConfig(presetFound, device.solution).settings;

    setNewSettings(parsedSettings);
    setModalProps({
      title: title,
      subtitle: subtitle,
      configs: {
        old: {
          title: configOldTitle,
          label: currentSelectedPresetName
        },
        new: {
          title: configNewTitle,
          label: presetFound.displayName
        }
      }
    });
    setIsModalOpen(true);
  };

  const saveChanges = () => {
    // Rename device is not a config change, so it should be handled differently
    if (device.displayName !== deviceCopy.displayName) {
      // SideFX routine will rename device on DM API and replicate changes on Redux
    } else {
      // Rename device and config changes won't happen at the same time

      if (utils.compareObjects(oldSettings, newSettings)) {
        // If there is no changes just turn off edit mode
        setIsEditable(false);
      } else {
        // Display compare modal otherwise
        const currentSelectedPresetName = getCurrentPreset().displayName;
        setModalProps({
          title: 'Are you sure you want to apply those changes?',
          subtitle: `You are applying your custom changes to "${currentSelectedPresetName}"`,
          configs: {
            old: {
              title: 'Current Config',
              label: currentSelectedPresetName
            },
            new: {
              title: 'New Config',
              label: 'Changes made by the user'
            }
          }
        });
        setIsModalOpen(true);
      }
    }
  };

  const cancelChanges = () => {
    setIsEditable(false);
    setDeviceCopy(utils.deepClone(device));
  };

  const getPresets = () => {
    if (device) {
      return (solutionsConfig[device.solution] || []).map(preset => ({
        label: preset.displayName,
        value: preset.id
      }));
    }
    return [];
  };

  const onDeviceDataChanged = (path, value) => {
    utils.set(deviceCopy, path, value);
    setDeviceCopy(utils.deepClone(deviceCopy));
  };

  const getPresetInfoMessage = () => {
    if (sections[0] === 'general') {
      return 'GENERAL';
    } else if (sections[0] === 'advanced') {
      return 'SOLUTION';
    }

    return 'GENERAL and SOLUTION';
  };

  if (isConfigLoaded) {
    return (
      <>
        <DeviceInputs
          device={deviceCopy}
          sections={sections}
          handleChange={onDeviceDataChanged}
          renderOnSidebar={renderOnSidebar}
          isEditable={isEditable}
          isAdminUser={isAdminUser}
        />

        <EditButton
          isEditable={isEditable}
          saveChanges={saveChanges}
          cancelChanges={cancelChanges}
          renderSmallButton={renderOnSidebar}
          changeEditModeState={changeEditModeState}
        />

        {showPresetList && (
          <div className="form-group modify-preset">
            <label>Preset</label>
            <small>{`Change or Restore all settings on this device. Will affect ${getPresetInfoMessage()} settings.`}</small>
            <div className="d-flex">
              <DropdownSearch
                title={'Search Solution Config'}
                selected={{
                  label: selectedPreset.displayName,
                  value: selectedPreset.id
                }}
                leftAlign
                openUp
                disabled={!isEditable}
                onSelect={handlePresetSelector}
                options={getPresets()}
              />
              <button
                disabled={!isEditable}
                className="btn btn-outline-primary border-0 ml-3"
                onClick={() => {
                  handlePresetSelector(null, true);
                }}
              >
                Reset to default
              </button>
            </div>
          </div>
        )}
        <PresetCompareModal
          title={modalProps.title}
          subtitle={modalProps.subtitle}
          isOpen={isModalOpen}
          configs={{
            old: modalProps.configs.old,
            new: modalProps.configs.new,
            content: parseForTable(device.solution, oldSettings, newSettings, sections[0])
          }}
          enabledSections={sections}
          applyChanges={() => {
            updateSolutionConfig();
            setIsModalOpen(false);
          }}
          handleClose={() => {
            setIsModalOpen(false);
            const parsedSettings = parse.presetToDeviceConfig(deviceCopy, device.solution);
            setNewSettings(parsedSettings.settings);
            // Restore selected preset on dropdown, after cancel changes
            setSelectedPreset(getCurrentPreset());
            setApplyingPreset(false);
          }}
        />
      </>
    );
  }

  return <Loading />;
};

export default Details;
