import config from '../../config';
import API from '../api';

const { baseUrl } = config.authApi;

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
};

const getUser = async uid => {
  const url = `${baseUrl}/accounts/${uid}`;
  const token = API.isImpersonating ? API.originalToken : API.access_token;
  return fetch(url, {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    },
    method: 'GET'
  }).then(API.generateResponse);
};

const updateUser = async (uid, data) => {
  const url = `${baseUrl}/accounts/${uid}`;
  return fetch(url, {
    method: 'PUT',
    headers: API.headers,
    body: JSON.stringify(data)
  }).then(API.generateResponse);
};

const getAccountDetails = async users => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/getManyAccountsDetails`;
  const token = API.isImpersonating ? API.originalToken : API.access_token;
  return fetch(url, {
    headers: {
      ...API.headers,
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({ uids: users }),
    method: 'POST'
  }).then(API.generateResponse);
};

const signup = async data => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/accounts`;
  return fetch(url, {
    headers: API.headers,
    body: JSON.stringify(data),
    method: 'POST'
  }).then(API.generateResponse);
};

const impersonateUser = async (userId, token) => {
  if (!token) {
    await API.refreshTokenWhenNeeded();
  }

  const url = `${baseUrl}/accounts/${userId}/impersonations`;
  // Prevent refreshToken
  API.isImpersonating = true;
  API.userId = userId;
  API.originalToken = token || API.access_token;

  return fetch(url, {
    method: 'POST',
    headers: {
      ...API.headers,
      Authorization: token ? `Bearer ${token}` : API.headers.Authorization
    }
  }).then(API.generateResponse);
};

export { getUser, getAccountDetails, updateUser, signup, impersonateUser };
