import React, { useEffect, useRef, useState } from 'react';
import simpleheat from 'simpleheat';
import DropdownSearch from '../dropdownSearch';
import MultipleFilters from '../multipleFilters';
import Loading from '../loading';
import API from '../../services/api';
import Legend from './legend';
import icons from '../../assets/icons';
import utils from '../../utils';
import filterUtils from '../../utils/filters';

import './styles.scss';

const solutionFilters = {
  audience: ['Gender', 'Age', 'Emotion'],
  traffic: ['Direction', 'Vehicle Type']
};

const Heatmap = ({ query, widget }) => {
  const [dimensions, setDimensions] = useState({ width: 600, height: 350, heatmapWidth: 80, heatmapHeight: 60 });
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [imageSrc, setImageSrc] = useState(icons.imageThumbnail);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [heatmapData, setHeatmapData] = useState([]);
  const [heatmapDevices, setHeatmapDevices] = useState([]);
  const [customAppliedFilters, setCustomAppliedFilters] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const canvasRef = useRef();
  const heatmapRef = useRef(null);

  useEffect(() => {
    if (selectedDevice) {
      drawHeatmap(dimensions.width, dimensions.height);
    }
  }, [heatmapData]);

  useEffect(() => {
    if (query && query.heatmapDevices) {
      const hasHeatmapDevices = query.heatmapDevices.filter(device => device.heatmapImageUrl !== '');
      setHeatmapDevices(hasHeatmapDevices);
      if (!utils.compareArrays(hasHeatmapDevices, heatmapDevices)) {
        setSelectedDevice(hasHeatmapDevices[0]);
      }
    }
  }, [query]);

  useEffect(() => {
    if (selectedDevice) {
      fetchDeviceConfig(selectedDevice);
      fetchHeatmapData(selectedDevice);
      clearHeatmap();
      setImageSrc(icons.imageThumbnail);
      setImgLoaded(false);
    }
  }, [selectedDevice]);

  useEffect(() => {
    if (selectedDevice) {
      fetchHeatmapData(selectedDevice);
      clearHeatmap();
    }
  }, [customAppliedFilters, query]);

  const getNormalizedData = (width, height) => {
    let newData = heatmapData;

    if (newData) {
      newData = newData.map(dataPoint => {
        const newX = (dataPoint.x * width) / dimensions.heatmapWidth;
        const newY = (dataPoint.y * height) / dimensions.heatmapHeight;
        return [newX, newY, dataPoint.value];
      });
      return newData;
    }
    return [];
  };

  const getSelectedOption = () => {
    if (!selectedDevice) {
      return {
        label: 'Select an option',
        value: 'None'
      };
    }
    return {
      label: selectedDevice.displayName,
      value: selectedDevice.id
    };
  };

  const clearHeatmap = () => {
    if (heatmapRef.current) {
      heatmapRef.current.clear().draw();
    }
  };

  const drawHeatmap = (width, height) => {
    if (!heatmapRef.current) {
      heatmapRef.current = simpleheat(canvasRef.current);
    }

    // Normalize coordinates, using cellSize
    const newData = getNormalizedData(width, height);
    // Get max value from datapoints
    const newMaxValue = getMaxValue(newData);

    // Radius and Blur size looks better when they are a bit bigger then width/cellsize
    const radius = width / (1.5 * dimensions.heatmapWidth);
    const blur = height / (1.5 * dimensions.heatmapHeight);

    if (selectedDevice.heatmapImageUrl) {
      // Add timestamp to image URL to ignore cache
      const dateTime = new Date().toISOString();
      setImageSrc(`${selectedDevice.heatmapImageUrl}?datetime=${dateTime}`);
      setImgLoaded(true);
    }

    heatmapRef.current.data(newData).max(newMaxValue).radius(radius, blur).draw();
  };

  const fetchDeviceConfig = device => {
    API.getDeviceConfig(device.companyId, device.id)
      .then(res => {
        updateDimensions(res);
      })
      .catch(console.error);
  };

  const fetchHeatmapData = device => {
    setIsLoadingData(true);
    const cid = device.companyId;
    const pid = device.projectId;
    const tid = device.solution.toLowerCase(); //TODO: check with new solution names (TRAFFIC / AUDIENCE / CUSTOM)
    const did = device.id;

    API.getHeatmapData(cid, pid, tid, did, query.startDate, query.endDate, customAppliedFilters)
      .then(res => {
        setHeatmapData(res.data);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoadingData(false);
      });
  };

  /*
   * Get max val from datapoints
   */
  const getMaxValue = dataset => {
    let max = 0;
    dataset.forEach(point => {
      if (point[2] > max) {
        max = point[2];
      }
    });

    return max;
  };

  const updateDimensions = deviceConfig => {
    const resolution = utils.get(deviceConfig, 'solutionConfig.driver_config.config.resolution');
    const heatmapResolution = utils.get(deviceConfig, 'solutionConfig.driver_config.config.heatmap_resolution');

    if (resolution && resolution.width && resolution.height) {
      // Fixed height, proportional width
      const newWidth = Math.round((resolution.width / resolution.height) * dimensions.height);
      setDimensions({
        height: dimensions.height, // Keep original height
        width: newWidth, // Only update width,
        heatmapWidth: heatmapResolution ? heatmapResolution.width : dimensions.heatmapWidth,
        heatmapHeight: heatmapResolution ? heatmapResolution.height : dimensions.heatmapHeight
      });
    }
  };

  const infoText = widget.info ? widget.info.text : null;

  return (
    <div className="heatmap-container">
      <div className="card">
        <div className="card-header">
          <h5>{widget.title}</h5>
          <div className="widget-action-buttons">
            <info-box widget-title={widget.title} host-id="widget-heatmap" text={infoText} />
          </div>
        </div>
        <div className="card-body">
          <div className="heatmap--header">
            <div id="heatmap--legend">
              <div className="legend--header">
                <div className="legend--label">Importance Index</div>
                <info-box
                  id="heatmap--legend--info"
                  tolltip="Importance Index"
                  widget-title="Importance Index"
                  host-id="heatmap--legend--info"
                  align-right
                  text="This index is a representation of time spent in an area and volume of sessions in the area. The nearest to red your area is, the longest was the time spent or the higher was the frequency of detections there."
                />
              </div>
              <Legend min="min" max="max" />
            </div>
            <div className="heatmap--filters">
              <DropdownSearch
                options={heatmapDevices.map(device => ({ ...device, label: device.displayName, value: device.id }))}
                selected={getSelectedOption()}
                onSelect={value => {
                  setSelectedDevice(heatmapDevices.find(device => device.id === value));
                }}
              />
              <MultipleFilters
                filters={filterUtils.getFilters(solutionFilters[query.selectedSolution.id], false)}
                items={[]}
                onFilter={(newFilteredItems, newAppliedFilters) => {
                  setCustomAppliedFilters(newAppliedFilters);
                }}
                customAppliedFilters={customAppliedFilters}
                renderAsButton
                onlyIcon
              />
            </div>
          </div>
          <div className="heatmap--canvas">
            <img
              className={imgLoaded ? 'loaded' : ''}
              alt="preview"
              src={imageSrc}
              width={dimensions.width}
              height={dimensions.height}
            />
            <canvas ref={canvasRef} width={dimensions.width} height={dimensions.height} id="canvas"></canvas>
            {isLoadingData && <Loading size={32} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Heatmap;
