import { call, put } from 'redux-saga/effects';
import types from '../redux/types';
import API from '../services/api';

export function* fetchCompanies() {
  try {
    const companies = yield call(API.getCompanies);
    if (companies) {
      yield put({ type: types.LOAD_COMPANIES, payload: companies.companies });
      yield put({ type: types.SELECT_COMPANY, payload: companies.companies[0] });
    }
  } catch (error) {
    console.error("Couldn't fetch companies");
  }
}
