import React, { useEffect, useState, useRef } from 'react';
import { AutoSizer, Grid, InfiniteLoader } from 'react-virtualized';
import { useDebouncedCallback } from 'use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../redux/dashboard/actions.js';
import { getUser } from '../../redux/user/actions.js';

import ToolBar from '../../components/toolbar';
import Loading from '../../components/loading';
import CustomCheckBox from '../../components/customCheckBox';
import SiteGroupCard from './siteGroupCard';
import DetailsDrawer from '../../components/detailsDrawer';
import Wizard from '../../components/wizard';
import EmptySiteGroups from './ui/emptySiteGroups.js';
import createSections from './siteGroupSections/createSections.js';
import editSections from './siteGroupSections/editSections.js';

import utils from '../../utils';
import { computeColumnCount, normalizeProject, prepareServerObject } from '../../utils/project';
import paginationUtils from '../../utils/pagination.js';
import API from '../../services/api';

import './styles.scss';
import { getSelectedCompany } from '../../redux/company/actions.js';

const SiteGroupsContainer = ({ lastUpdated }) => {
  const [siteGroups, setSiteGroups] = useState([]);
  const [totalSiteGroups, setTotalSiteGroups] = useState(0);
  const [selectedSiteGroup, setSelectedSiteGroup] = useState({});
  const [nextPageToken, setNextPageToken] = useState('');

  const [searchBy, setSearchBy] = useState('');
  const [ownerFilter, setOwnerFilter] = useState('none');
  const [ownersMap, setOwnersMap] = useState({});
  const [currentFilters, setCurrentFilters] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMoreContent, setIsLoadingMoreContent] = useState(true);

  const [editMode, setEditMode] = useState(false);
  const [counters, setCounters] = useState({ userCounter: 0 });

  const [hideDrawer, setHideDrawer] = useState(true);
  const [createMode, setCreateMode] = useState(false);
  const [newSiteGroup, setNewSiteGroup] = useState({});

  const itemStatusMap = useRef({});
  const user = useSelector(getUser);
  const isAdmin = user && user.admin;
  const company = useSelector(getSelectedCompany);

  const dispatch = useDispatch();

  const CARD_OUTER_PADDING = 24; // Space between cards
  const CONTAINER_OUTER_ELEMENTS_HEIGHT = 183; // Everything out of container
  const ROW_HEIGHT = 190; // Grid row height
  const LOADING = 1;
  const LOADED = 2;
  const BATCH_ROW_SIZE = 10;

  useEffect(() => {
    itemStatusMap.current = {};
    loadMoreItems(0, BATCH_ROW_SIZE - 1, true);
    setIsLoading(true);
  }, [company, searchBy, ownerFilter, lastUpdated, isAdmin]);

  useEffect(() => {
    getOwnerInfo();
    setHideDrawer(true);
  }, [siteGroups]);

  const confirmChange = callback => {
    const question = 'Are you sure you want to do that? Changes will not be saved.';
    const shouldContinue = window.confirm(question) ? true : false;
    if (shouldContinue) {
      setEditMode(false);
      if (callback) callback();
    }
  };

  const clearSiteGroups = () => {
    setIsLoading(true);
    setNextPageToken('');
    setSelectedSiteGroup({});
    setHideDrawer(true);
    itemStatusMap.current = {};
    loadMoreItems(0, BATCH_ROW_SIZE - 1, true);
  };

  const updateCounters = (counterName, counterValue) => {
    counters[counterName] = counterValue;
    setCounters({ ...counters });
  };

  const updateSiteGroup = siteGroup => {
    API.updateSiteGroup(company.id, prepareServerObject(siteGroup), [
      'preferences',
      'displayName',
      'description',
      'enabledIntegrations'
    ])
      .then(() => {
        dispatch(
          setAlert(utils.generateAlert(`Preferences for Site Group "${siteGroup.displayName}" has changed.`, 'success'))
        );
        clearSiteGroups();
      })
      .catch(error => {
        dispatch(setAlert(utils.generateAlert(error.message, 'error')));
      });
  };

  const handleSearch = useDebouncedCallback(term => {
    // Clean up items cache and status everytime searchBy changes
    setNextPageToken('');
    setSearchBy(term);
    setIsLoading(true);
  }, 300);

  const handleSiteGroupSelection = siteGroup => {
    if (editMode) {
      confirmChange(() => {
        setSelectedSiteGroup(siteGroup);
      });
    } else {
      setSelectedSiteGroup(siteGroup);
      setHideDrawer(false);
    }
  };

  // Get owners email from authAPI
  const getOwnerInfo = () => {
    const ownerIds = siteGroups.map(siteGroup => siteGroup.ownerId);
    if (ownerIds.length > 0) {
      API.getAccountDetails(ownerIds)
        .then(ownerList => {
          const currentOwnersMap = utils.deepClone(ownersMap);
          setOwnersMap({ ...currentOwnersMap, ...ownerList });
        })
        .catch(console.error);
    }
  };

  const handleCloseDrawer = () => {
    if (editMode) {
      confirmChange();
    }
    setHideDrawer(true);
    setSelectedSiteGroup({});
  };

  const onCreate = () => {
    const credentials = newSiteGroup.credentials;

    delete newSiteGroup.credentials;
    delete newSiteGroup.errors;
    delete newSiteGroup.preferences.coreSpeedUnit;

    const siteGroupObject = {
      companyId: company.id,
      companyDisplayName: company.displayName,
      ownerId: user.id,
      ...newSiteGroup
    };

    API.createSiteGroup(company.id, siteGroupObject)
      .then(result => {
        credentials.map(item => {
          const credentialObject = item.credential;
          const credentialApiObject = {
            displayName: credentialObject.displayName,
            projectId: result.id,
            companyId: company.id,
            creatorId: user.id,
            credential: credentialObject.credential
          };
          API.createCredential(company.id, credentialApiObject)
            .then(() => {})
            .catch(error => {
              dispatch(setAlert(utils.generateAlert('Something went wrong. Try again later!', 'error')));
              console.error(error);
            });
        });
        dispatch(setAlert(utils.generateAlert('SiteGroup created successfully!', 'success')));
      })
      .catch(error => {
        dispatch(setAlert(utils.generateAlert('Something went wrong. Try again later!', 'error')));
        console.error(error);
      })
      .finally(() => {
        setCreateMode(false);
        setNewSiteGroup({});
        loadMoreItems(0, BATCH_ROW_SIZE - 1, true);
      });
  };

  const addPreferences = preferences => {
    if (!preferences.speedUnit) {
      // Initialize speedUnit to Miles/Hour as standard
      preferences.speedUnit = 'MILES_PER_HOUR';
    }
    newSiteGroup.preferences = preferences;
  };

  const getFilters = () => {
    const searchQuery = searchBy !== '' ? `(displayName ILIKE "%${searchBy}%" OR id ILIKE "%${searchBy}%")` : '';
    const ownerQuery = ownerFilter !== 'none' ? `(ownerId = "${user.id}")` : '';

    const hasFilterAndSearch = searchQuery !== '' && ownerQuery !== '' ? ' AND ' : '';

    return `${searchQuery}${hasFilterAndSearch}${ownerQuery}`;
  };

  const handleApiResult = (filter, reload, res) => {
    // Update counter only when querying all siteGroups
    if (filter === '') {
      setTotalSiteGroups(res.totalSize);
    }

    // Reset list when filter changes
    if (filter !== currentFilters || reload) {
      setCurrentFilters(filter);
      setSiteGroups(res.projects.map(project => normalizeProject(project)));
    } else {
      const newProjects = utils.mergeArraysByField(res.projects, siteGroups, 'id');
      setSiteGroups(normalizeProject(newProjects));
    }

    return res.nextPageToken;
  };

  //#region API Methods
  const fetchSiteGroups = async reload => {
    const filter = encodeURI(getFilters());

    if (filter !== currentFilters) {
      setSiteGroups([]);
    }

    if (isAdmin) {
      return API.getSiteGroups(40, nextPageToken, filter).then(res => {
        return handleApiResult(filter, reload, res);
      });
    } else {
      if (company) {
        return API.getSiteGroupsByCompany(company.id, 40, nextPageToken, filter).then(res => {
          return handleApiResult(filter, reload, res);
        });
      }
    }
  };

  const loadMoreItems = async (startIndex, stopIndex, reload = false) => {
    setIsLoadingMoreContent(true);
    paginationUtils.setItemsStatus(startIndex, stopIndex, itemStatusMap.current, LOADING);

    // Perform fetch on parent component
    return fetchSiteGroups(reload).then(newPageToken => {
      paginationUtils.setItemsStatus(startIndex, stopIndex, itemStatusMap.current, LOADED);
      setIsLoading(false);
      setIsLoadingMoreContent(false);
      setNextPageToken(newPageToken);
    });
  };

  const renderLoadingMoreContent = () => {
    if (!isLoadingMoreContent) {
      return null;
    }
    return (
      <div className="d-flex justify-content-center align-items-center">
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
      </div>
    );
  };

  if (createMode) {
    return (
      <div className="sitegroup-creation">
        <Wizard
          title={'Create a new site group'}
          icon={'project'}
          steps={createSections(newSiteGroup, setNewSiteGroup, addPreferences)}
          onSubmit={onCreate}
          onCancel={() => {
            setCreateMode(false);
            setNewSiteGroup({});
          }}
        />
      </div>
    );
  }

  const cellRenderer = ({ columnIndex, key, rowIndex, style }, columnCount) => {
    const index = rowIndex * columnCount + columnIndex;
    const currentSiteGroup = siteGroups[index];

    if (!currentSiteGroup) return;

    return (
      <div key={key} style={style}>
        <SiteGroupCard
          ownersMap={ownersMap}
          siteGroup={currentSiteGroup}
          selectedSiteGroup={selectedSiteGroup}
          onSelect={siteGroup => {
            handleSiteGroupSelection(siteGroup);
          }}
        />
      </div>
    );
  };

  const renderSiteGroupsList = () => {
    if (siteGroups.length === 0) {
      return <EmptySiteGroups />;
    }
    return (
      <div className="sitegroups-container">
        <div style={{ width: '100%', height: window.innerHeight - CONTAINER_OUTER_ELEMENTS_HEIGHT }}>
          {siteGroups && siteGroups.length > 0 && (
            <AutoSizer>
              {({ width, height }) => {
                const columnCount = computeColumnCount(width);
                const columnWidth = (width - CARD_OUTER_PADDING) / columnCount;
                const rowCount = Math.ceil(siteGroups.length / columnCount);
                const totalRows = Math.ceil(siteGroups.length / columnCount);

                return (
                  <InfiniteLoader
                    isRowLoaded={params => {
                      const lastRowIndex = params.index + 1;
                      // Return false will trigger ´loadMoreRows`. This happen only when there is an empty row (itemStatusMap.current[index] is empty)
                      return lastRowIndex <= totalRows
                        ? paginationUtils.isRowLoaded(lastRowIndex, itemStatusMap.current)
                        : true;
                    }}
                    loadMoreRows={({ startIndex, stopIndex }) => loadMoreItems(startIndex, stopIndex + BATCH_ROW_SIZE)}
                    threshold={5}
                    minimumBatchSize={BATCH_ROW_SIZE}
                    rowCount={rowCount}
                  >
                    {({ onRowsRendered, registerChild }) => (
                      <Grid
                        ref={registerChild}
                        onSectionRendered={params =>
                          onRowsRendered({ startIndex: params.rowStartIndex, stopIndex: params.rowStopIndex })
                        }
                        className={`sitegroups-grid ${selectedSiteGroup ? 'drawer-open' : ''}`}
                        containerStyle={{ overflowY: 'auto' }}
                        cellRenderer={({ columnIndex, key, rowIndex, style }) =>
                          cellRenderer({ columnIndex, key, rowIndex, style }, columnCount)
                        }
                        height={height}
                        width={width}
                        rowCount={rowCount}
                        columnCount={columnCount}
                        rowHeight={ROW_HEIGHT}
                        columnWidth={columnWidth}
                      />
                    )}
                  </InfiniteLoader>
                );
              }}
            </AutoSizer>
          )}
        </div>
        <DetailsDrawer
          title="Site Group Details"
          isEditing={editMode}
          onFocusChange={() => {}}
          onClose={handleCloseDrawer}
          sections={editSections(selectedSiteGroup, updateSiteGroup, editMode, setEditMode, counters, updateCounters)}
          hidden={hideDrawer}
        />
      </div>
    );
  };

  return (
    <div className="sitegroups">
      <ToolBar
        title="Site Groups"
        icon="projectDarkOutline"
        searchPlaceholder={'Search site groups'}
        count={totalSiteGroups > 0 ? totalSiteGroups : null}
        onSearch={handleSearch}
        renderFilters={
          <>
            <CustomCheckBox
              label={'Owned Site Groups'}
              selected={ownerFilter}
              onClick={() => {
                setOwnerFilter(ownerFilter !== 'none' ? 'none' : 'all');
                setIsLoading(true);
                setNextPageToken('');
              }}
            />
            {renderLoadingMoreContent()}
          </>
        }
        hasButton
        buttonTitle="Create Site Group"
        buttonPress={() => {
          setCreateMode(true);
        }}
      />
      {isLoading || !siteGroups ? <Loading size={15} /> : renderSiteGroupsList()}
    </div>
  );
};

export default SiteGroupsContainer;
