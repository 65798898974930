import config from '../../config';
import API from '../api';

const { baseUrl } = config.coreApi;

/**
 * Creates a new license.
 * @param {Object} license - The license object to be created.
 * @returns {Promise<Object>} The created license response.
 */
const createLicense = async license => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/licenses`;

  return fetch(url, {
    headers: API.headers,
    method: 'POST',
    body: JSON.stringify(license)
  }).then(res => API.generateResponse(res));
};

/**
 * Lists all licenses.
 * @param {number} [pageSize=200] - The number of licenses to retrieve per page.
 * @returns {Promise<Object>} The list of licenses.
 */
const listLicenses = async (pageSize = 200) => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/licenses?pageSize=${pageSize}`;
  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Gets a specific license by ID.
 * @param {string} lid - The license ID.
 * @returns {Promise<Object>} The license details.
 */
const getLicense = async lid => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/licenses/${lid}`;

  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Retrieves licenses associated with a specific project.
 * @param {string} pid - The project ID.
 * @returns {Promise<Object>} The licenses associated with the project.
 */
const getLicensesFromProject = async pid => {
  await API.refreshTokenWhenNeeded();
  const filter = encodeURIComponent(`projectId=${pid}`);

  const url = `${baseUrl}/licenses?pageSize=200&filter=${filter}`;

  return fetch(url, {
    headers: API.headers,
    method: 'GET'
  }).then(res => API.generateResponse(res));
};

/**
 * Updates a license.
 * @param {string} lid - The license ID.
 * @param {Object} license - The license object with updated fields.
 * @param {string[]} fields - The fields to update in the license.
 * @returns {Promise<Object>} The updated license response.
 */
const updateLicense = async (lid, license, fields) => {
  await API.refreshTokenWhenNeeded();

  const path = fields.map(field => `updateMask=${field}`).join('&');
  const url = `${baseUrl}/licenses/${lid}?${path}`;

  return fetch(url, {
    method: 'PATCH',
    headers: API.headers,
    body: JSON.stringify(license)
  }).then(res => API.generateResponse(res));
};

/**
 * Deletes a license.
 * @param {string} lid - The license ID.
 * @returns {Promise<Object>} The response from the delete operation.
 */
const deleteLicense = async lid => {
  await API.refreshTokenWhenNeeded();

  const url = `${baseUrl}/licenses/`;

  return fetch(url, {
    method: 'DELETE',
    body: JSON.stringify({ names: lid }),
    headers: API.headers
  }).then(res => res.json());
};

export { getLicense, deleteLicense };
