import types from '../types.js';

export const loadProjects = () => {
  return { type: types.LOAD_PROJECTS, payload: null };
};

export const createProject = payload => {
  return { type: types.CREATE_PROJECT, payload };
};

export const selectProject = payload => {
  return { type: types.SELECT_PROJECT, payload };
};

export const clearProject = () => {
  return { type: types.CLEAR_PROJECT, payload: null };
};

export const updateSelectedProject = payload => {
  return { type: types.UPDATE_SELECTED_PROJECT, payload };
};

export const updateProjects = payload => {
  return { type: types.UPDATE_PROJECTS, payload };
};

export const getProjects = state => {
  return state.projectReducer.projects;
};

export const getSelectedProject = state => {
  return state.projectReducer.selectedProject;
};
