import config from '../../config';
import API from '../api';

const baseUrl = config.authApi.baseUrlV2;

/**
 * Confirms an invite.
 * @param {Invite} invite - Invite to confirm.
 * @returns {Promise<Object>} The response from the confirm operation.
 */
const confirmInvite = async invite => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/invites:confirm`;
  return fetch(url, {
    method: 'POST',
    headers: API.headers,
    body: JSON.stringify(invite)
  }).then(res => API.generateResponse(res));
};

/**
 * Gets information about an invite.
 * @param {string} token - The invite token.
 * @returns {{
 *   projectOrCompany: string;
 *   userExists: boolean;
 *   valid: boolean;
 * }} The invite information.
 */
const getInviteInfo = async token => {
  await API.refreshTokenWhenNeeded();
  const url = `${baseUrl}/invites:validate`;
  return fetch(url, {
    headers: API.headers,
    method: 'POST',
    body: JSON.stringify({ token })
  }).then(res => API.generateResponse(res));
};

export { confirmInvite, getInviteInfo };
