import React from 'react';

import PersonalInformation from './personal-information';
// import ChangePassword from './change-password';

import icons from '../../assets/icons';

const UserProfile = ({ user, onChange }) => {
  return (
    <div className="profile-cards user-profile">
      <div className="card">
        <div className="card-header">
          <img src={icons.userDark} alt="user icon" />
          <h3>Profile</h3>
        </div>
        <div className="card-body">
          <PersonalInformation user={user} onChange={onChange} />
          {/* <ChangePassword email={user ? user.email : ""} onChange={onChange}/> */}
        </div>
      </div>
    </div>
  );
};
export default UserProfile;
