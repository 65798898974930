import * as authEndpoints from './auth';
import * as cmsEndpoints from './cms';
import * as companyEndpoints from './company';
import * as deviceEndpoints from './devices';
import * as inviteEndpoints from './invites';
import * as licenseEnpoints from './license';
import * as mappingsEndpoints from './mappings';
import * as queryEndpoints from './query';
import * as reportEndpoints from './reports';
import * as siteGroupEndpoints from './sitegroups';
import * as siteEndpoints from './sites';
import * as templateEndpoints from './template';
import * as userEndpoints from './users';
import * as widgetsEndpoints from './widgets';

export default {
  ...authEndpoints,
  ...cmsEndpoints,
  ...companyEndpoints,
  ...deviceEndpoints,
  ...inviteEndpoints,
  ...licenseEnpoints,
  ...mappingsEndpoints,
  ...queryEndpoints,
  ...reportEndpoints,
  ...siteGroupEndpoints,
  ...siteEndpoints,
  ...templateEndpoints,
  ...userEndpoints,
  ...widgetsEndpoints
};
