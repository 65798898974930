import { setState } from '../../utils/redux.js';
import types from '../types.js';

const initialState = {
  solutionsConfig: {}
};

const presetReducer = (state = initialState, action) => {
  const { LOAD_SOLUTIONS_CONFIG, UPDATE_SOLUTION_CONFIG, CREATE_SOLUTION_CONFIG } = types;

  switch (action.type) {
    case LOAD_SOLUTIONS_CONFIG:
      return setState(state, 'solutionsConfig', action.payload);
    case UPDATE_SOLUTION_CONFIG: {
      const configId = action.payload.id;
      const solutionId = action.payload.solutionId;
      const solutionsConfigByType = [...state.solutionsConfig[solutionId]];
      const configFound = solutionsConfigByType.findIndex(config => config.id === configId);
      solutionsConfigByType[configFound] = { ...action.payload };

      return setState(state, 'solutionsConfig', {
        ...state.solutionsConfig,
        [solutionId]: solutionsConfigByType
      });
    }
    case CREATE_SOLUTION_CONFIG: {
      const solutionId = action.payload.solutionId;
      return {
        ...state,
        solutionsConfig: {
          ...state.solutionsConfig,
          [solutionId]: [...state.solutionsConfig[solutionId], action.payload]
        }
      };
    }
    default:
      return state;
  }
};

export default presetReducer;
