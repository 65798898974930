const dev = {
  dashboard: {
    baseUrl: 'https://dev-dashboard.admobilize.com'
  },
  legacyDashboard: {
    baseUrl: 'https://dev-dashboard-legacy.admobilize.com'
  },
  deviceManagementApi: {
    baseUrl: 'https://dev-devicemanagementapi.admobilize.com/v2'
  },
  notificationApi: {
    baseUrl: 'https://dev-notificationsapi.admobilize.com/v1beta1'
  },
  iamApi: {
    baseUrl: 'https://dev-iam.admobilize.com/v1alpha1'
  },
  authApi: {
    baseUrl: 'https://dev-auth.admobilize.com/v2',
    baseUrlV2: 'https://dev-xauth.admobilize.com/api/v1'
  },
  sentry: {
    dsn: 'https://b55e3c47322b403ea39d8d3a469ac6a3@sentry.io/1519633'
  },
  googleAnalytics: {
    trackingId: ''
  },
  hotjar: {
    hjid: 0,
    hjsv: 0
  },
  queryApi: {
    baseUrl: 'https://dev-dashboard.admobilize.com/api/v1',
    legacyBaseUrl: 'https://dev-queryapi.admobilize.com/v1alpha1'
  },
  cmsApi: {
    baseUrl: 'https://dev-cmsintegrationsapi.admobilize.com/v1'
  },
  mapsApi: {
    baseUrl: 'https://maps.googleapis.com/maps/api',
    key: 'AIzaSyDvNSFxFr1FZADTykmr2lOJ7OXy-e434T0',
    libraries: ['geocoding', 'places']
  },
  widgetApi: {
    baseUrl: 'https://dev-widgets.admobilize.com'
  },
  coreApi: {
    baseUrl: 'https://dev-dashboard.admobilize.com/api/v1'
  }
};

const prod = {
  dashboard: {
    baseUrl: 'https://dashboard.admobilize.com'
  },
  legacyDashboard: {
    baseUrl: 'https://dashboard-legacy.admobilize.com'
  },
  deviceManagementApi: {
    baseUrl: 'https://devicemanagementapi.admobilize.com/v2'
  },
  notificationApi: {
    baseUrl: 'https://notificationsapi.admobilize.com/v1beta1'
  },
  iamApi: {
    baseUrl: 'https://iam.admobilize.com/v1alpha1'
  },
  authApi: {
    baseUrl: 'https://auth.admobilize.com/v2',
    baseUrlV2: 'https://xauth.admobilize.com/api/v1'
  },
  sentry: {
    dsn: 'https://b55e3c47322b403ea39d8d3a469ac6a3@sentry.io/1519633'
  },
  googleAnalytics: {
    trackingId: 'UA-38180903-32'
  },
  hotjar: {
    hjid: 2316520,
    hjsv: 6
  },
  queryApi: {
    baseUrl: 'https://dashboard.admobilize.com/api/v1',
    legacyBaseUrl: 'https://queryapi.admobilize.com/v1alpha1'
  },
  cmsApi: {
    baseUrl: 'https://cmsintegrationsapi.admobilize.com/v1'
  },
  mapsApi: {
    baseUrl: 'https://maps.googleapis.com/maps/api',
    key: 'AIzaSyDvNSFxFr1FZADTykmr2lOJ7OXy-e434T0',
    libraries: ['geocoding', 'places']
  },
  widgetApi: {
    baseUrl: 'https://widgets.admobilize.com'
  },
  coreApi: {
    baseUrl: 'https://dashboard.admobilize.com/api/v1'
  }
};

const stage = {
  dashboard: {
    baseUrl: 'https://stage-dashboard.admobilize.com'
  },
  legacyDashboard: {
    baseUrl: 'https://dashboard-legacy.admobilize.com'
  },
  deviceManagementApi: {
    baseUrl: 'https://devicemanagementapi.admobilize.com/v2'
  },
  notificationApi: {
    baseUrl: 'https://notificationsapi.admobilize.com/v1beta1'
  },
  iamApi: {
    baseUrl: 'https://iam.admobilize.com/v1alpha1'
  },
  authApi: {
    baseUrl: 'https://auth.admobilize.com/v2',
    baseUrlV2: 'https://xauth.admobilize.com/api/v1'
  },
  sentry: {
    dsn: 'https://b55e3c47322b403ea39d8d3a469ac6a3@sentry.io/1519633'
  },
  googleAnalytics: {
    trackingId: 'UA-38180903-32'
  },
  hotjar: {
    hjid: 2316520,
    hjsv: 6
  },
  queryApi: {
    baseUrl: 'https://stage-dashboard.admobilize.com/api/v1',
    legacyBaseUrl: 'https://queryapi.admobilize.com/v1alpha1'
  },
  cmsApi: {
    baseUrl: 'https://cmsintegrationsapi.admobilize.com/v1'
  },
  mapsApi: {
    baseUrl: 'https://maps.googleapis.com/maps/api',
    key: 'AIzaSyDvNSFxFr1FZADTykmr2lOJ7OXy-e434T0',
    libraries: ['geocoding', 'places']
  },
  widgetApi: {
    baseUrl: 'https://widgets.admobilize.com'
  },
  coreApi: {
    baseUrl: 'https://stage-dashboard.admobilize.com/api/v1'
  }
};

const envConfig = process.env.REACT_APP_STAGE === 'prod' ? prod : process.env.REACT_APP_STAGE === 'stage' ? stage : dev;

const config = {
  ...envConfig,
  // Add common config values here,
  commitHash: 'e9d47d86',
  appVersion: 'v5'
};

export default config;
