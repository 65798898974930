// Common
import React, { useState, useRef, useCallback } from 'react';

// Components
import components from '../../components';
import DeviceActionButtons from './DeviceActionButtons';
import BulkActionButtons from './bulkActionButtons';
import tableHeaders from './tableHeaders';

// Utilities
import deviceUtils from '../../utils/device';
import API from '../../services/api';

const { Table } = components;

const DevicesTable = ({
  project,
  company,
  searchBy,
  filterBy,
  isItemDetailsClosed,
  isAdminUser,
  lastUpdated,
  isAdvertiserUser,
  onItemSelected,
  onDeviceCountChanged
}) => {
  // Table hooks
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [itemsCount, setItemsCount] = useState(0);
  const [initialSortColumn] = useState('displayName');

  const nextPageToken = useRef('');
  const filters = useRef('');
  const sorting = useRef('displayName, id');
  const devices = useRef([]);
  const oldProjectId = useRef('');
  const oldLastUpdated = useRef(lastUpdated);

  const getFilters = () => {
    let newFilters = [`projectID = '${project.id}'`];

    if (searchBy !== '') {
      newFilters.push(`(displayName ILIKE "%${searchBy}%" OR id ILIKE "%${searchBy}%")`);
    }

    if (!isAdminUser) {
      newFilters.push('(archived = false)');
    }

    filterBy.forEach(filter => {
      const operator = filter.operator ? filter.operator : '=';
      const value = filter.type === 'version' ? `"${filter.value}"` : filter.value;
      newFilters.push(`(${filter.path} ${operator} ${value})`);
    });

    return newFilters.join(' AND ');
  };

  const getSorting = sortBy => {
    if (sortBy.length > 0 && sortBy[0].id) {
      return `${sortBy[0].id} ${sortBy[0].desc ? 'DESC' : 'ASC'}, id`;
    }

    return (sorting.current = 'displayName, id');
  };

  const getDeviceList = (newDevices, forceUpdate) => {
    // Merge new data with previous data if sorting and filters hasn't change
    if (!forceUpdate) {
      return [...devices.current, ...newDevices];
    }

    // Overwrite list with new data
    return newDevices;
  };

  // This is called when the table needs new data
  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      // Keep old sorting and filters to handle changes
      const oldSorting = sorting.current;
      const oldFilters = filters.current;

      // Apply Sorting and filters
      filters.current = encodeURI(getFilters());
      sorting.current = getSorting(sortBy);

      const forceUpdate =
        oldSorting !== sorting.current ||
        oldFilters !== filters.current ||
        lastUpdated !== oldLastUpdated.current ||
        project.id !== oldProjectId.current;

      // Clear pageToken if project, filter or sorting has changed
      if (forceUpdate) {
        nextPageToken.current = '';
        setLoading(true);
      }

      // Fetch new page
      API.getDevices(company.id, nextPageToken.current, filters.current, 15, sorting.current).then(response => {
        // Normalize devices adding extra info
        const newDevices = deviceUtils.addInformation({ devices: response.devices });

        // Update device list
        devices.current = getDeviceList(newDevices, forceUpdate);

        nextPageToken.current = response.nextPageToken;
        oldProjectId.current = project.id;

        // Compute total number of items
        setItemsCount(response.totalSize);

        // Update item count when there is no filters applied
        if (filters.current === '') {
          onDeviceCountChanged(response.totalSize);
        }

        // Update table indexes
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;

        // Slice items from device list
        setData(devices.current.slice(startRow, endRow));

        // Compute total page count
        setPageCount(Math.ceil(response.totalSize / pageSize));
        setLoading(false);
      });
    },
    [searchBy, filterBy, initialSortColumn, isAdminUser, project, lastUpdated]
  );

  const renderActionButtons = device => (
    <DeviceActionButtons device={device} isAdminUser={isAdminUser} isAdvertiserUser={isAdvertiserUser} />
  );

  const renderBulkActionButtons = () => (
    <BulkActionButtons devices={selectedDevices} isAdvertiserUser={isAdvertiserUser} />
  );

  return (
    <Table
      columns={tableHeaders}
      data={data}
      dataLabel={'devices'}
      fetchData={fetchData}
      loading={loading}
      searchBy={searchBy}
      filterBy={filterBy}
      pageCount={pageCount}
      itemsCount={itemsCount}
      resetOnChange={project} // reset table when project change
      hasInteractiveRows
      initialSortColumn={initialSortColumn.id}
      isItemDetailsClosed={isItemDetailsClosed}
      onItemSelected={onItemSelected}
      onDataSelected={setSelectedDevices}
      bulkActionButtons={renderBulkActionButtons}
      actionButtons={device => renderActionButtons(device)}
    />
  );
};

export default DevicesTable;
