import types from '../types.js';
import { setState } from '../../utils/redux.js';

const initialState = {
  summary: {
    templates: null,
    selected: null
  },
  analytics: {
    templates: null,
    selected: null
  }
};

const templateReducer = (state = initialState, action) => {
  const { LOAD_SUMMARY_TEMPLATES, LOAD_ANALYTICS_TEMPLATES, SELECT_SUMMARY_DASHBOARD, SELECT_ANALYTICS_DASHBOARD } =
    types;

  switch (action.type) {
    case LOAD_SUMMARY_TEMPLATES:
      return setState(state, 'summary', {
        ...state.summary,
        templates: action.payload
      });
    case LOAD_ANALYTICS_TEMPLATES:
      return setState(state, 'analytics', {
        ...state.analytics,
        templates: action.payload
      });
    case SELECT_SUMMARY_DASHBOARD:
      return setState(state, 'summary', {
        ...state.summary,
        selected: action.payload
      });
    case SELECT_ANALYTICS_DASHBOARD:
      return setState(state, 'analytics', {
        ...state.analytics,
        selected: action.payload
      });
    default:
      return state;
  }
};

export default templateReducer;
